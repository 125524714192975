import moment from "moment";
export const dateFormatter = (dayString) => {
  let date = new Date(dayString);
  let value = moment.utc(date).format("MMMM D, YYYY");
  return value !== "Invalid date" ? value : "-";
};
//Add one years
export function addOneYear(date) {
  date.setFullYear(date.getFullYear() + 1);
  return date;
}
export const dateTimeFormatter = (dayString) => {
  let dateWithTime = moment(dayString).format("DD/MM/YYYY HH:mm:A");
  return dateWithTime !== "Invalid date" ? dateWithTime : "-";
};
//Get local timezone
export const getLocalTimeZone = (dateString) => {
  let dateValue = moment(dateString).local().toDate();
  return dateValue ?? "-";
};
export const reactDateFormatter = (dayString) => {
  let date = new Date(dayString);
  // const formattedDate = date.toLocaleDateString('en-US');
  return date;
};

export function addDaysToDate(dayString, days) {
  let date = new Date(dayString);
  let value = moment(date, "MMMM D, YYYY")
    .add(days, "days")
    .format("MMMM D, YYYY");
  return value !== "Invalid date" ? value : "-";
}

export function addYearsToDate(dayString, years) {
  let date = new Date(dayString);
  let value = moment(date, "MMMM D, YYYY")
    .add(years, "years")
    .format("MMMM D, YYYY");
  return value !== "Invalid date" ? value : "-";
}

export const currentDate = () => {
  let date = new Date();
  let value = moment.utc(date).format("MMMM D, YYYY");
  return value !== "Invalid date" ? value : "-";
};

export const pendingExpiryDays = (dayString) => {
  const targetDate = moment(dayString, "YYYY-MM-DD");
  const currentDate = moment();
  const pendingDays = targetDate.diff(currentDate, "days");
  return pendingDays > 0 ? pendingDays : "0";
};
