import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";
import {
  addEntityThunk,
  deleteEntityThunk,
  getEntityThunk,
  getTrashEntityThunk,
  removeTrashEntityThunk,
  restoreEntityThunk,
  updateEntityThunk,
} from "./entityThunk";

const initialState = {
  activeEntity: [],
  inActiveEntity: [],
  entities: [],
  error: "",
  page: 1,
  limit: 10,
  total: 0,
  loading: false,
  //Trashed entities
  trashEntity: [],
  trashEntityPage: 1,
  trashEntityLimit: 10,
  trashEntityTotal: 0,
};

const entitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    changeEntityLimit: (state, action) => {
      state.limit = action.payload;
    },
    changeEntityPage: (state, action) => {
      state.page = action.payload;
    },
    changeTrashEntityLimit: (state, action) => {
      state.limit = action.payload;
    },
    changeTrashEntityPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEntityThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("New entity added successfully");

          const { doc } = action.payload.data.data;
          state.loading = false;

          state.entity = { ...state.entity, doc };
        }
      })
      .addCase(addEntityThunk.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(addEntityThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
        handleOopsError();
      })
      .addCase(getEntityThunk.fulfilled, (state, action) => {
        const { entities } = action.payload.data?.data;

        let tempActive = [];
        let tempInactive = [];
        if (entities.length > 0) {
          // eslint-disable-next-line array-callback-return
          entities.map((item) => {
            if (item.active === true) {
              tempActive.push(item);
            } else {
              tempInactive.push(item);
            }
          });
        }

        state.activeEntity = [...tempActive];
        state.inActiveEntity = [...tempInactive];
        state.total = action?.payload?.data?.total;
        state.entities = action?.payload?.data?.data;
        state.loading = false;
      })

      .addCase(deleteEntityThunk.fulfilled, (state, action) => {
        state.loading = false;
        handleSuccess("Entity removed successfully!");
      })
      .addCase(deleteEntityThunk.rejected, (state, action) => {
        state.loading = false;
        handleOopsError();
      })
      .addCase(deleteEntityThunk.pending, (state, action) => {
        state.loading = true;
      })
      //Trash section reducer
      .addCase(getTrashEntityThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { entities } = action.payload.data?.data;
          state.trashEntity = [...entities];
          state.trashEntityTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })
      .addCase(restoreEntityThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })
      .addCase(removeTrashEntityThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })
      .addCase(updateEntityThunk.fulfilled, (state, action) => {
        state.loading = false;
        handleSuccess("Entity updated successfully!");
      })
      ;
  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const {
  changeEntityLimit,
  changeEntityPage,
  changeTrashEntityLimit,
  changeTrashEntityPage,
} = entitySlice.actions;
export default entitySlice.reducer;
