import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const addEntityTaskThunk = createAsyncThunk(
  "entityTask/add",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(body, "body", config, "config");
    try {
      const data = await axios.post(`${api_url}/tasks`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);





export const deleterTaskThunk = createAsyncThunk(
  "entityTask/delete",
  async ({ headers, _id, params }) => {
  
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params:params
    };
    try {
      const data = await axios.delete(`${api_url}/tasks/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);

export const getEntityTaskThunk = createAsyncThunk(
  "entityTask/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/entities/${_id}/task`, config);


      return data ;
    } catch (error) {
      return error;
    }
  }
);

//task  update thunk
export const updateTaskThunk = createAsyncThunk(
  'task/update',
  async ({ body, headers ,_id }) => {
    console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
     
    }
    try {
      const data = await axios.patch(`${api_url}/tasks/${_id}`, body, config)
      return data
    } catch (error) {
      return error
    }
 })
 