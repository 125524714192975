import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;
const quickbookApiUrl = process.env.REACT_APP_QUICKBOOK_API_URL;

export const addOrderThunk = createAsyncThunk(
  "orders/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/orders`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const addPlanThunk = createAsyncThunk(
  "plans/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/plans`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const editPlanThunk = createAsyncThunk(
  "plans/edit",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/plans/${body._id}`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const getPlansThunk = createAsyncThunk(
  "plans/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/plans`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const getActiveSubscriptionThunk = createAsyncThunk(
  "activeSubscription/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(
        `${api_url}/users/active/subscription`,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getOrdersThunk = createAsyncThunk(
  "orders/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/orders`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getOrderDetailThunk = createAsyncThunk(
  "order/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/orders/${_id}`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const updateOrderThunk = createAsyncThunk(
  "orders/update",
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/orders/${_id}`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const updateOrderEntityThunk = createAsyncThunk(
  "orders/updateEntity",
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(
        `${api_url}/orders/updateEntity/${_id}`,
        body,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const deleteOrderThunk = createAsyncThunk(
  "orders/delete",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/orders/${_id}`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const viewInvoiceThunk = createAsyncThunk(
  "invoice/view",
  async ({ AccessToken, invoiceId, companyID }) => {
    const config = {
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + AccessToken,
      },
    };
    try {
      const data = await axios.get(
        `${quickbookApiUrl}/v3/company/${companyID}/invoice/${invoiceId}/pdf`,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const quickbookCreateCustomerThunk = createAsyncThunk(
  "quickbook/createCutomer",
  async ({ AccessToken, companyID, body }) => {
    const config = {
      headers: {
        Authorization: "Bearer " + AccessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/text',
      },
    };
    try {
      const data = await axios.post(
        `${quickbookApiUrl}/v3/company/${companyID}/customer`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookGetCustomerThunk = createAsyncThunk(
  "quickbook/getCustomer",
  async ({ AccessToken, companyID, DisplayName }) => {
    const config = {
      headers: {
        Authorization: "Bearer " + AccessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/text',
      },
    };
    try {
      const data = await axios.get(
        `${quickbookApiUrl}/v3/company/${companyID}/query?query=select * from Customer where DisplayName='${DisplayName}'`,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookCreateInvoiceThunk = createAsyncThunk(
  "quickbook/createInvoice",
  async ({ AccessToken, companyID, body }) => {
    const config = {
      headers: {
        Authorization: "Bearer " + AccessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/text',
      },
    };
    try {
      const data = await axios.post(
        `${quickbookApiUrl}/v3/company/${companyID}/invoice`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const sendDocumentThunk = createAsyncThunk(
  "sendDocuments",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      }
    };
    try {
      const data = await axios.post(`${api_url}/orders/sendDocument`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
