import { createSlice } from "@reduxjs/toolkit";
import { handleError, handleSuccess } from "../../../../utils/toastMessage";
import {
  addOwnerShipThunk, deleteOwnerShipThunk, getOwnerShipThunk
} from "./ownerShipThunk";

const initialState = {
  parent: [],
  subsidiary: [],
  error: "",
  pageParent: 1,
  limitParent: 10,
  totalParent: 0,
  pageSubsidiary: 1,
  limitSubsidiary: 10,
  totalSubsidiary: 0,
  loading: false,
};

const ownershipSlice = createSlice({
  name: "ownership",
  initialState,
  reducers: {
    changeSubsidiaryLimit: (state, action) => {
      state.limitSubsidiary = action.payload;
    },
    changeSubsidiaryPage: (state, action) => {
      state.pageSubsidiary = action.payload;
    },
    changeParentLimit: (state, action) => {
      state.limitParent = action.payload;
    },
    changeParentPage: (state, action) => {
      state.pageParent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOwnerShipThunk.fulfilled, (state, action) => {
        if(action.payload.status === 201){
          handleSuccess("New ownership added successfully");
          const { doc } = action.payload.data.data;
          state.loading = false;
          state.ownership = { ...state.ownership, doc };
        } else{
          handleError("Oops! Something went wrong!")
         
        }
      
      })
     
      //  get group
      .addCase(getOwnerShipThunk.fulfilled, (state, action) => {
        state.loading = false;
        const { ownership } = action.payload.data.data;
        let tempParent = [];
        let tempSubsidiary = [];
        const {type}= action?.payload?.data;
        if(type === "Parent Ownership"){
          if (ownership.length > 0) {
            //eslint-disable-next-line array-callback-return
            ownership.map((item) => {
              tempParent.push(item);
              
            });
            state.parent = [...tempParent];
            state.totalParent = action?.payload?.data?.count;
   
          }
        }
        else{
         
          if (ownership.length > 0) {
            // eslint-disable-next-line array-callback-return
              ownership.map((item) => {
              tempSubsidiary.push(item);
              
            });
            state.subsidiary = [...tempSubsidiary];
            state.totalSubsidiary = action?.payload?.data?.count;
   
          }
        }
        
       
       
      })
      .addCase(getOwnerShipThunk.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getOwnerShipThunk.rejected, (state, action) => {
        state.error = action.payload.data.message;
        handleError("Oops! Something went wrong!")
       
      })

      .addCase(deleteOwnerShipThunk.fulfilled, (state, action) => {
        state.loading = false; 
         if (action?.payload?.data.status === 201) {
          handleSuccess("Removed successfully!")
        }
       
      })
      .addCase(deleteOwnerShipThunk.rejected, (state, action) => {
        state.loading = false;
        handleError("Oops! Something went wrong!");
        
      })
      .addCase(deleteOwnerShipThunk.pending, (state, action) => {
        state.loading = true;
      });
  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const { changeSubsidiaryLimit, changeSubsidiaryPage, changeParentLimit, changeParentPage } =
  ownershipSlice.actions;
export default ownershipSlice.reducer;
