import * as FileSaver from 'file-saver';
export const setEntityUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const setToken = (token , isRemember) => {
if(isRemember){
    localStorage.setItem("userToken", JSON.stringify(token));
  }
  else{
    sessionStorage.setItem("userToken",  JSON.stringify(token));

  }
};
 export function joinWithCommas(arr) {
  if (arr.length === 0) {
    return '';
  } else if (arr.length === 1) {
 
    return arr[0].title.toString();
  } else {
    const str = arr.slice(0, -1).map(val => val.title.toString()).join('-');
    const lastVal = arr[arr.length - 1].title.toString();
    return `${str},  ${lastVal}`;
  }
}
function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // add headers
  const headers = Object.keys(array[0]);
  str += headers.map(header => `"${header}"`).join(',') + '\r\n';

  // add data
  array.forEach(obj => {
    const line = headers.map(header => {
      const value = obj[header];
      return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
    }).join(',');
    str += line + '\r\n';
  });

  return str;
}

export function handleExportToCsv(data,name) {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function handleExportToJson (data,name){
  const json = JSON.stringify(data, null, '\t');
const blob = new Blob([json], {type: 'application/json'});
const url = URL.createObjectURL(blob);

const link = document.createElement('a');
link.href = url;
link.download = `${name}`;
link.click();

URL.revokeObjectURL(url);
}
//Save to text file

 export const handleSaveToTextFile = (data,name) => {
const jsonData = JSON.stringify(data, null, 2); // Convert data to JSON with indentation of 2 spaces

  const blob = new Blob([jsonData], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, `${name}.txt`);
}
