import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../utils/toastMessage";
import { getAlertsThunk, getAlertDetailThunk } from "./alertThunk";

const initialState = {
  alerts: [],
  page: 1,
  limit: 10,
  total: 0,
  alert: {}
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //Trash section reducer
      .addCase(getAlertsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.alerts = action.payload.data?.data?.alerts;
          state.total = action.payload.data?.total;
        }
      })

      .addCase(getAlertDetailThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.alert = action.payload.data?.data;
        }
      })
  },
});

export const { changeLimit, changePage } = alertSlice.actions;

export default alertSlice.reducer;
