import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";
import { addDesignationThunk, getdesignationThunk } from "./designationThunk";

const initialState = {
  designations: [],
  page: 1,
  limit: 10,
  total: 0,
  designationOptions: [],
};

const designationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getdesignationThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          let options = action.payload.data?.data?.data.map((item) => {
            return {
              label: item?.title,
              value: item?.title,
            };
          });
          state.designationOptions = options;
          state.designations = action.payload.data?.data?.data;
          state.total = action.payload.data?.total;
        }
      })

      .addCase(addDesignationThunk.fulfilled, (state, action) => {
        if (action.payload?.status === 201) {
          handleSuccess("Designation saved successfully!");
        }
      });
  },
});

export const { changeLimit, changePage } = designationSlice.actions;

export default designationSlice.reducer;
