import "./App.css";
import "../src/assets/css/style.css";
import React from "react";
import Layout from "./Routing/Layout";
import Routing from "./Routing/Routing";


function App() {

  
  return (
    <>
      <Layout>
        <Routing />
      </Layout>


    </>
  );
}

export default App;
