import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`

// get group thunk
export const getRolesThunk = createAsyncThunk(
  'roles/get',
  async ({ headers, params }) => {
 
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params: params
    }
    try {
      const data = await axios.get(`${api_url}/roles`, config)
    
      return data

    } catch (error) {
      // return error

    }
  }
)

