import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";
import {
  getJurisdictionStatsThunk,
  getOrganizationStatsThunk,
  getTagStatsThunk,
  getReportsThunk,
  saveReportThunk,
  updateReportThunk,
  getTaskStatsThunk
} from "./reportThunk";

const initialState = {
  jurisdictionStats: [],
  organizationStats: [],
  tagStats: [],
  taskStats:[],
  reports: [],
  page: 1,
  limit: 10,
  total: 0,
};


const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //Trash section reducer
      .addCase(getJurisdictionStatsThunk.fulfilled, (state, action) => {
         if(action.payload.status === 200){
            state.jurisdictionStats = action.payload.data?.data?.result;
         }
      })

      .addCase(getOrganizationStatsThunk.fulfilled, (state, action) => {
          if(action.payload.status === 200){
            state.organizationStats = action.payload.data?.data?.result;
         }
      })
      
      .addCase(getTagStatsThunk.fulfilled, (state, action) => {
          if(action.payload.status === 200){
            state.tagStats = action.payload.data?.data?.result;
         }
      })
      .addCase(getTaskStatsThunk.fulfilled, (state, action) => {
        if(action.payload.status === 200){
          state.taskStats = action.payload.data?.data?.result;
       }
    })
      .addCase(getReportsThunk.fulfilled, (state, action) => {
          if(action.payload.status === 200){
            state.reports = action.payload.data?.data;
            state.total = action.payload.data?.total;
          }
      })

      .addCase(saveReportThunk.fulfilled, (state, action) => {
        if(action.payload?.status === 201){
          handleSuccess("Report saved successfully!");
        }
      })

      .addCase(updateReportThunk.fulfilled, (state, action) => {
        if(action.payload?.status === 200){
          handleSuccess("Report updated successfully!");
        }
      })
  },
});

export const {
  changeLimit,
  changePage,
} = reportSlice.actions;

export default reportSlice.reducer;
