import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getAlertsThunk = createAsyncThunk(
  "alerts/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/alert`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getAlertDetailThunk = createAsyncThunk(
  "alerts/detail",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      }
    };
    try {
      const data = await axios.get(`${api_url}/alert/${params.id}`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
