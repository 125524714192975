import React, { useEffect } from "react";
import Sidebar from "../common/Sidebar";
import { useSelector } from "react-redux";
import { authSelector } from "../features/auth/authSelector";
import { useNavigate } from 'react-router-dom';

const Layout = (props) => {
  const { token, isUserVerified } = useSelector(authSelector);
  const navigate = useNavigate()
  useEffect(() => {
    if (!token || token === "undefined" || token == null) {
      navigate("/signin");
    }
  }, []);
  return (
    <>
      <div className="content">
        <div className="main_content">
          {token && <Sidebar />}
          {props.children}
        </div>
      </div>
    </>
  );
};
export default Layout;
