import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;
 

//  customer add thunk
export const addEditCustomersThunk = createAsyncThunk(
  "customer/addEdit",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/customer/createUpdate`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

 

// get customer thunk
export const getCustomersThunk = createAsyncThunk(
  "customer/getAll",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/customer/customers`, config);

      return data;
    } catch (error) {
      // return error
    }
  }
);
 
// delete customer thunk
export const deleteCustomersThunk = createAsyncThunk(
  "customer/delete",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/customer/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);

// update customer thunk
// export const updateCustomersThunk = createAsyncThunk(
//   "customer/update",
//   async ({ body, headers, _id }) => {
//     const config = {
//       headers: {
//         Authorization: headers.Authorization,
//       },
//     };
//     try {
//       const data = await axios.patch(`${api_url}/users/${_id}`, body, config);
//       return data;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const getCustomerThunk = createAsyncThunk(
//   "customer/get",
//   async ({ headers, _id }) => {
//     const config = {
//       headers: {
//         Authorization: headers.Authorization,
//       },
//     };
//     try {
//       const data = await axios.get(`${api_url}/customers/${_id}`, config);
//       return data;
//     } catch (error) {
//       return error;
//     }
//   }
// );

 

 

 