import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboardAlertsThunk,
  getDashboardBillingThunk,
  getDashboardOtherRequirementsThunk,
  getDashboardReportsThunk,
  getDashboardTaskThunk,
  getDashboardThunk,
  getUsersAnswerAverageThunk,
  getUsersAnswersDetailsThunk,
} from "./dashboardThunk";

const initialState = {
  //dashboard initial value
  dashboardContents: [],
  annualReportData: [],
  annualReportLimit: 10,
  annualReportPage: 1,
  annualReportTotal: 0,
  //Other Requirements initial value
  otherRequirementsData: [],
  otherRequirementsLimit: 10,
  otherRequirementsPage: 1,
  otherRequirementsTotal: 0,
  //alert initial value
  alertPage: 1,
  alertLimit: 10,
  alertTotal: 0,
  alertData: [],
  loading: false,
  //Task initial value
  taskData: [],
  taskPage: 1,
  taskLimit: 10,
  taskTotal: 0,
  //billing initial value
  billingData: [],
  billingPage: 1,
  billingLimit: 10,
  billingTotal: 0,

  usersList: [],

  userAnswersDetails: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeAnnualReportLimit: (state, action) => {
      state.annualReportLimit = action.payload;
    },
    changeAnnualReportPage: (state, action) => {
      state.annualReportPage = action.payload;
    },
    changeOtherRequirementsLimit: (state, action) => {
      state.otherRequirementsLimit = action.payload;
    },
    changeOtherRequirementsPage: (state, action) => {
      state.otherRequirementsPage = action.payload;
    },
    changeDashboardAlertLimit: (state, action) => {
      state.alertLimit = action.payload;
    },
    changeDashboardAlertPage: (state, action) => {
      state.alertPage = action.payload;
    },
    changeDashboardTaskLimit: (state, action) => {
      state.taskLimit = action.payload;
    },
    changeDashboardTaskPages: (state, action) => {
      state.taskPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getUsersAnswersDetailsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.userAnswersDetails = action.payload?.data?.data;
        }
      })
      .addCase(getDashboardThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { data } = action.payload.data.data;
          let temp = [["Requirements", "Total"]];
          data.forEach((obj) => {
            const location = obj.title;
            const count = obj.count;
            temp.push([location, count]);
          });
          state.dashboardContents = [...temp];
        }
      })
      .addCase(getDashboardReportsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { requirement } = action.payload.data.data;
          state.annualReportData = [...requirement];
          state.annualReportTotal = action.payload.data.total;
        }
      })
      .addCase(
        getDashboardOtherRequirementsThunk.fulfilled,
        (state, action) => {
          if (action.payload.status === 200) {
            const { requirement } = action.payload.data.data;
            state.otherRequirementsData = [...requirement];
            state.otherRequirementsTotal = action.payload.data.total;
          }
        }
      )
      .addCase(getUsersAnswerAverageThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { data } = action.payload.data.data;
          state.usersList = [...data];
          // state.otherRequirementsTotal = action.payload.data.total;
        }
      })
      .addCase(getDashboardAlertsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { alerts } = action.payload.data.data;
          state.alertData = [...alerts];
          state.alertTotal = action.payload.data.total;
        }
      })
      .addCase(getDashboardTaskThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { task } = action.payload.data.data;
          state.taskData = [...task];
          state.taskTotal = action.payload.data.total;
        }
      })
      .addCase(getDashboardBillingThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { orders } = action.payload.data.data;
          state.billingData = [...orders];
          state.billingTotal = action.payload.data.total;
        }
      });
  },
});

export const {
  changeAnnualReportLimit,
  changeAnnualReportPage,
  changeOtherRequirementsLimit,
  changeOtherRequirementsPage,
  changeDashboardAlertLimit,
  changeDashboardAlertPage,
  changeDashboardTaskLimit,
  changeDashboardTaskPages,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
