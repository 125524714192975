import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;
const quickbookApiUrl = process.env.REACT_APP_QUICKBOOK_API_URL;

//  document  add thunk
export const addServiceThunk = createAsyncThunk(
  "services/add",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/services`, body, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const editServiceThunk = createAsyncThunk(
  "services/edit",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/services/${body._id}`, body, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);
// get group thunk
export const getServiceThunk = createAsyncThunk(
  "services/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/services`, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);

// delete group thunk
export const deleterServiceThunk = createAsyncThunk(
  "services/delete",
  async ({ headers, _id }) => {
    // console.log({headers,_id});
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/services/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);

export const getCategoryServicesThunk = createAsyncThunk(
  "categoryservices/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/categoryservices`, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookCreateItemThunk = createAsyncThunk(
  "quickbook/createItem",
  async ({ AccessToken, companyID, body }) => {
    const config = {
      headers: {
        Authorization: "Bearer " + AccessToken,
      },
    };
    try {
      const data = await axios.post(
        `${quickbookApiUrl}/v3/company/${companyID}/item`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookGetItemThunk = createAsyncThunk(
  "quickbook/getItem",
  async ({ AccessToken, companyID, serviceName }) => {
    const config = {
      headers: {
        Authorization: "Bearer " + AccessToken,
      },
    };
    try {
      const data = await axios.get(
        `${quickbookApiUrl}/v3/company/${companyID}/query?query=select * from Item where Name='${serviceName}'`,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);
