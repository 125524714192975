import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;



// get alerts thunk
export const getAlertsThunk = createAsyncThunk(
  'alerts/get',
  async ({ headers,params ,_id }) => {
    
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
        const data = await axios.get(`${api_url}/entities/${_id}/alerts`, config)
   
      return {data , params}

    } catch (error) {
      return error

    }
  }
)




