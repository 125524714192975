import { createSlice } from "@reduxjs/toolkit";
import { addTagsThunk, deleteTagsThunk, getTagsThunk } from "./tagsThunk";
import { handleOopsError, handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  tag: {},
  tags: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
}

const tagsSlice=createSlice({
  name:"tags",
  initialState,
  reducers:{
    changeLimitTags: (state, action) => {

      state.limit = action.payload;
    },
    changePageTags: (state, action) => {

      state.page = action.payload;
    }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(addTagsThunk.fulfilled, (state, action) => {
     if(action.payload){
        const { doc } = action.payload.data.data
        state.tags={...state.tags,doc}
        handleSuccess('Tag saved successfuly')
      }
      
    })
     //get document
   .addCase(getTagsThunk.fulfilled, (state, action) => {
    const {data} = action.payload
    state.tags = {...state.tags, data }
    state.total = action?.payload?.data?.total
    state.result = action?.payload?.data?.results
    })
  .addCase(getTagsThunk.pending, (state, action) => {     
 
  })

  .addCase(getTagsThunk.rejected, (state, action) => {
   })


  .addCase(deleteTagsThunk.fulfilled, (state, action) => {
  })


  }
})


export const { changeLimitTags, changePageTags } = tagsSlice.actions
export default tagsSlice.reducer;