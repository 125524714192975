import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../../utils/toastMessage";
import {
  getCurrenciesThunk,
  getDeliveryPartnersThunk,
  addCurrencyThunk,
  deleteCurrencyThunk,
  addDeliveryPartnerThunk,
  deleteDeliveryPartnerThunk
} from "./currencyThunk";

const initialState = {
  currencies: [],
  currencyPage: 1,
  currencyLimit: 10,
  currencyTotal: 0,

  deliveryPartners: [],
  deliveryPartnerPage: 1,
  deliveryPartnerLimit: 10,
  deliveryPartnerTotal: 0,
};


const currencySlice = createSlice({
  name: "trash",
  initialState,
  reducers: {
    changeCurLimit: (state, action) => {
      state.currencyLimit = action.payload;
    },
    changeCurPage: (state, action) => {
      state.currencyPage = action.payload;
    },
    changeDelPartLimit: (state, action) => {
      state.deliveryPartnerLimit = action.payload;
    },
    changeDelPartPage: (state, action) => {
      state.deliveryPartnerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    
      .addCase(getCurrenciesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.currencies = action.payload?.data?.data?.data;
          state.currencyTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(getDeliveryPartnersThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.deliveryPartners = action.payload?.data?.data?.data;
          state.deliveryPartnerTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(addCurrencyThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Currency added successfully");
        }
      })

      .addCase(deleteCurrencyThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Currency deleted successfully");
        }
      })

      .addCase(addDeliveryPartnerThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Delivery partner added successfully");
        }
      })

      .addCase(deleteDeliveryPartnerThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Delivery partner deleted successfully");
        }
      })
  },
});

export const {
  changeCurLimit,
  changeCurPage,
  changeDelPartLimit,
  changeDelPartPage,
} = currencySlice.actions;

export default currencySlice.reducer;
