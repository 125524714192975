import { createSlice } from "@reduxjs/toolkit";
import { addThunk, deleteThunk, getThunk } from "./folderThunk";
import { handleOopsError, handleSuccess } from "../../../../utils/toastMessage";

const initialState = {
  folderOptions: [],
  // data: [],
  folder: {},
  folders: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
};

const folderSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data;
        state.folders = { ...state.folders, doc };
        handleSuccess("Folder details saved successfuly");
      })
      .addCase(addThunk.pending, (state, action) => {
        // console.log({ action });
      })

      .addCase(addThunk.rejected, (state, action) => {})

      //  get document
      .addCase(getThunk.fulfilled, (state, action) => {
        // console.log("get documents slice", action.payload);
        const { data } = action.payload.data.data;

        state.total = action?.payload?.data?.total;
        state.result = action?.payload?.data?.results;
        let tempOptions = [];
        if (data.length > 0) {
          for (let item of data) {
            tempOptions.push({ value: item._id, label: item.title });
          }
          state.folders = { ...state.folders, data };
          state.folderOptions = [...tempOptions];
        }
      })
      .addCase(getThunk.pending, (state, action) => {
        // console.log(action)
      })

      .addCase(getThunk.rejected, (state, action) => {
        // console.log(action)
      })

      .addCase(deleteThunk.fulfilled, (state, action) => {
        // console.log("delete api", action.payload);
        // state.groups.filter((item) => item.id !== action.payload._id)
      });
  },
});

export const { changeLimit, changePage } = folderSlice.actions;
export default folderSlice.reducer;
