import { createSlice } from "@reduxjs/toolkit";

import { handleSuccess } from "../../../utils/toastMessage";
import {
  addEditCustomersThunk,
  deleteCustomersThunk,
  getCustomersThunk,
} from "./customerThunk";

const initialState = {
  customer: {},
  customers: [],
  page: 1,

  error: "",
  loading: false,
  total: 0,
  limit: 10,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.currentPage = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEditCustomersThunk.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        const { customers } = action.payload.data.data;

        // state.customers = { ...state.customers, customers };
        handleSuccess("Customers detail saved successfully.");
      })
      .addCase(addEditCustomersThunk.pending, (state, action) => {
        console.log({ action });
      })

      .addCase(addEditCustomersThunk.rejected, (state, action) => {
        console.log({ action });
      })

      //  get customers
      .addCase(getCustomersThunk.fulfilled, (state, action) => {
       
        const { customers } = action?.payload?.data?.data;
        state.customers = { ...state.customers, customers };
        state.total = action?.payload?.data?.total;
      })

      .addCase(deleteCustomersThunk.fulfilled, (state, action) => {
        console.log("delete api", action.payload);
        // state.customers.filter((item) => item.id !== action.payload._id);
      });
  },
});

export const { changePage, changeLimit } = customerSlice.actions;
export default customerSlice.reducer;
