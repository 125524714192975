import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`


//  document  add thunk
export const addDocumentTypeThunk = createAsyncThunk(
  'documenttypes/add',
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/documenttypes`, body, config)

      // console.log(data)
      return data
    } catch (error) {

      return error
    }


  }

)

// get group thunk
export const getDocumentTypeThunk = createAsyncThunk(
  'documenttypes/get',
  async ({ headers,params }) => {
    // console.log("khdusgfif",params);
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/documenttypes`, config)
      // console.log(data);
      return data

    } catch (error) {
      return error

    }
  }
)

// delete group thunk
export const deleteDocumentTypeThunk = createAsyncThunk(
  'documenttypes/delete',
  async ({ headers,_id }) => {
    // console.log({headers,_id});
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/documenttypes/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }


  }
)


