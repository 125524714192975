import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getJurisdictionStatsThunk = createAsyncThunk(
  "jurisdictionStats/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(
        `${api_url}/entities/stats/jurisdictions`,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getOrganizationStatsThunk = createAsyncThunk(
  "organizationStats/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(
        `${api_url}/entities/stats/organizationForms`,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTagStatsThunk = createAsyncThunk(
  "tagStats/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/entities/stats/tags`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
export const getTaskStatsThunk = createAsyncThunk(
  "taskStats/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };

    try {
      const data = await axios.get(`${api_url}/entities/stats/tasks`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getReportsThunk = createAsyncThunk(
  "reports/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      // if (type == "people") {
      //   const data = await axios.get(`${api_url}/reports/forPeople`, config);
      //   return data;
      // } else {
      const data = await axios.get(`${api_url}/reports`, config);
      return data;
      //}
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const saveReportThunk = createAsyncThunk(
  "reports/post",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/reports`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const updateReportThunk = createAsyncThunk(
  "reports/update",
  async ({ headers, body, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/reports/${_id}`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const deleteReportThunk = createAsyncThunk(
  "reports/delete",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/reports/${_id}`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
