import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`
//  requirements  add thunk
export const addRequirementsThunk = createAsyncThunk(
  "requirements/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/requirements`, body, config);

      return data;
    } catch (error) {
      return error.response;
    }
  }
);

// get requirements thunk
export const getRequirementsThunk = createAsyncThunk(
  "requirements/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/requirements`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// delete requirements thunk
export const deleteRequirementsThunk = createAsyncThunk(
  "requirements/delete",
  async ({ headers, _id, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params
    };
    try {
      const data = await axios.delete(`${api_url}/requirements/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
// get entity requirements thunk
export const getEntityAnnualRequirementsThunk = createAsyncThunk(
  "annualrequirements/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/entities/${_id}/requirements`, config);
      return { data, _id, params };
    } catch (error) {
      return error;
    }
  }
);

export const getEntityOtherRequirementsThunk = createAsyncThunk(
  "otherrequirements/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/entities/${_id}/requirements`, config);
      return { data, _id, params };
    } catch (error) {
      return error;
    }
  }
);

//requirements update thunk
export const updateRequirementsThunk = createAsyncThunk(
  'requirements/update',
  async ({ body, headers, _id }) => {
    console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization,

      },

    }
    try {
      const data = await axios.patch(`${api_url}/requirements/${_id}`, body, config)
      return data
    } catch (error) {
      return error
    }


  }

)