import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleError, handleSuccess } from "../../../../utils/toastMessage";

import {
  addRequirementsThunk,
  deleteRequirementsThunk,
  getRequirementsThunk,
  getEntityAnnualRequirementsThunk,
  getEntityOtherRequirementsThunk,
  updateRequirementsThunk
} from "./requirementsThunk";

const initialState = {
  requirements: [],
  requirementsOption: [],
  annualReport: [],
  annualPage: 1,
  annualLimit: 10,
  annualTotal: 0,
  otherRequirements: [],
  otherPage: 1,
  otherLimit: 10,
  otherTotal: 0,
  loading: false,
};

const requirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    changeAnnualRequirementLimit: (state, action) => {
      state.annualLimit = action.payload;
    },
    changeAnnualRequirementsPage: (state, action) => {
      state.annualPage = action.payload;
    },
    changeOtherRequirementsLimit: (state, action) => {
      state.otherLimit = action.payload;
    },
    changeOtherRequirementsPage: (state, action) => {
      state.otherPage = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(addRequirementsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("Requirements added successfully");
          const { doc } = action.payload.data.data;
          state.loading = false;
          state.entity = { ...state.entity, doc };
        } else {
          handleError("Oops! Something went wrong!")

        }
      })

      .addCase(addRequirementsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
        handleError("Oops! Something went wrong!")


      })

      .addCase(deleteRequirementsThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data.status === 201) {
          handleSuccess("Removed successfully!")

        } else {
          handleError("Oops! Something went wrong!")

        }
      })
      .addCase(deleteRequirementsThunk.rejected, (state, action) => {
        state.loading = false;
        handleError("Oops! Something went wrong!")

      })
      .addCase(getEntityAnnualRequirementsThunk.fulfilled, (state, action) => {

        state.annualReport = action?.payload?.data?.data?.data?.Requirements;
        state.annualTotal = action?.payload?.data?.data?.total;
      })

      .addCase(getEntityOtherRequirementsThunk.fulfilled, (state, action) => {

        state.otherRequirements = action?.payload?.data?.data?.data?.Requirements;
        state.otherTotal = action?.payload?.data?.data?.total;
      })
      .addCase(getRequirementsThunk.pending, (state, action) => {
        state.loading = true;
      })


      .addCase(updateRequirementsThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.status === 201) {
          handleSuccess("Details updated successfully!")

        } else {
          handleError("Oops! Something went wrong!")

        }
      })
  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const {
  changeAnnualRequirementLimit,
  changeAnnualRequirementsPage,
  changeOtherRequirementsPage,
  changeOtherRequirementsLimit,
} = requirementsSlice.actions;
export default requirementsSlice.reducer;
