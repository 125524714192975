import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./features/auth/authSlice";
import groupsSlice from "./features/settings/users/groupSlice";
import documentTypeSlice from "./features/settings/folders/loockuptabs/documentType/documentTypeSlice";
import organisationSlice from "./features/settings/folders/loockuptabs/organisation/organisationSlice";
import tagsSlice from "./features/settings/folders/loockuptabs/tags/tagsSlice";
import recipientSlice from "./features/settings/users/recipientSlice";
import roleSlice from "./features/settings/users/roleSlice";
import folderSlice from "./features/settings/folders/folder/folderSlice";
import customFieldsSlice from "./features/settings/folders/customFields/customSlice";
import jurisdictionSlice from "./features/settings/folders/loockuptabs/jurisdiction/jurisdictionSlice";
import requirementsTypesSlice from "./features/settings/folders/loockuptabs/requirementTypes/requirementTypesSlice";

import usersSlice from "./features/settings/users/usersSlice";
import serviceSlice from "./features/settings/folders/loockuptabs/servicesType/serviceSlice";
import taskSlice from "./features/admin/taskSlice";
import entitySlice from "./components/admin/Entity/entitySlice";
import documentsSlice from "./features/documentSlice/documentsSlice";
import ownershipSlice from "./components/admin/Entitydetails/ownership/ownerShipSlice";
import requirementsSlice from "./components/admin/Entitydetails/requirments/requirementsSlice";
import peopleSlice from "./components/admin/People/peopleSlice";
import alertsSlice from "./components/admin/Entitydetails/alerts/alertsSlice";

import notesSlice from "./components/admin/Entitydetails/Notes/notesSlice";
import entityTaskSlice from "./components/admin/Entitydetails/tasks/entityTaskSlice";
import dashboardSlice from "./components/admin/Dashboard/dashboardSlice";
import registerAgentSlice from "./components/admin/RegisterAgent/registerAgentSlice";
//Parent and Subsidiaries
import parentSubsdiariesSlice from "./components/admin/Parentsubsidiaries/parentSubsdiariesSlice";
import jurisdictionsByUserSlice from "./components/admin/Jurisdication/jurisdicationByUserSlice";
import trashSlice from "./components/admin/setting/Trash/trashSlice";
import contactSlice from "./components/admin/contact/contactSlice";
import orderSlice from "./components/admin/setting/Subscription/orderSlice";
import reportSlice from "./components/admin/Reports/reportSlice";
import currencySlice from "./components/admin/setting/currency/currencySlice";
import alertSlice from "./features/alert/alertSlice";
import taskCommentSlice from "./features/admin/comments/taskCommentSlice";
import designationSlice from "./components/admin/Entitydetails/designationSlice"; 
import customerSlice from "./features/settings/customers/customerSlice";
import informationSlice from "./components/admin/Entitydetails/InformationSheet/informationSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    group: groupsSlice,
    documentType: documentTypeSlice,
    organisations: organisationSlice,
    tags: tagsSlice,
    recipients: recipientSlice,
    folders: folderSlice,
    customFields: customFieldsSlice,
    jurisdictions: jurisdictionSlice,
    requirementsTypes: requirementsTypesSlice,
    users: usersSlice,
    services: serviceSlice,
    tasks: taskSlice,
    entity: entitySlice,
    documents: documentsSlice,
    ownership: ownershipSlice,
    requirements: requirementsSlice,
    peoples: peopleSlice,
    alerts: alertsSlice,
    notes: notesSlice,
    entityTask: entityTaskSlice,
    dashboard: dashboardSlice,
    registerAgent: registerAgentSlice,
    parentAndSubsidiaries: parentSubsdiariesSlice,
    jurisdictionsByUser: jurisdictionsByUserSlice,
    roles: roleSlice,
    trash: trashSlice,
    contacts: contactSlice,
    orders: orderSlice,
    report: reportSlice,
    currency: currencySlice,
    alert: alertSlice,
    taskComment: taskCommentSlice,
    designation: designationSlice,
    customers: customerSlice,
    informations: informationSlice,


  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
