import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL_DEV;

//registerAgent add thunk
export const addRegisterAgentThunk = createAsyncThunk(
  "registerAgent/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/register-agent`, body, config);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);
//delete registerAgent thunk
export const deleteRegisterAgentThunk = createAsyncThunk(
  "registerAgent/delete",
  async ({ headers, _id, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.delete(
        `${api_url}/register-agent/${_id}`,
        config
      );
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
// update registerAgent thunk
export const updateRegisterAgentThunk = createAsyncThunk(
  "registerAgent/update",
  async ({ headers, body }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(
        `${api_url}/register-agent/${body._id}`,body,
        config
      );
      return  data ;
    } catch (error) {
      return error;
    }
  }
);
// get registerAgent thunk
export const getRegisterAgentThunk = createAsyncThunk(
  "registerAgent/getAll",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/register-agent`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
