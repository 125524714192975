import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`


//document  ownership thunk
export const addOwnerShipThunk = createAsyncThunk(
  'ownership/add',
  async ({ body, headers },{rejectWithValue}) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/ownership`, body, config)
     return data
    } catch (error) {
      return error.response
    }


  }

)

// get ownership thunk
export const getOwnerShipThunk = createAsyncThunk(
  'ownership/get',
  async ({ headers,params ,_id }) => {
    
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
        const data = await axios.get(`${api_url}/entities/${_id}/ownership`, config)
   
      return data

    } catch (error) {
      return error

    }
  }
)


// delete ownership thunk
export const deleteOwnerShipThunk = createAsyncThunk(
  'ownership/delete',
  async ({ headers,_id, params }) => {

    const config = {
      headers: {
        "Authorization":  headers.Authorization
      },
      params: params
    }
    try {
      const data = await axios.delete(`${api_url}/ownership/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }
  }
)
//update ownership thunk
export const updateOwnerShipThunk = createAsyncThunk(
  'update/ownership',
  async ({ headers, body ,_id }) => {
  const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.patch(`${api_url}/ownership/${_id}`,body,config)

      return {data,_id}

    } catch (error) {
      return error
    }
  }
)
