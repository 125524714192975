import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../utils/toastMessage";

const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`


//  document  add thunk
export const addEntityThunk = createAsyncThunk(
  'entity/add',
  async ({ body, headers },{rejectWithValue}) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/entities`, body, config)

 
      return data
    } catch (error) {
      // rejectWithValue(error.response.data)

      // console.log({error});
      return error.response
    }


  }

)

// get entity thunk
export const getEntityThunk = createAsyncThunk(
  'entity/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/entities`, config)
      return data

    } catch (error) {
      console.log(error,"error");
      return error

    }
  }
)
// get entity thunk
export const getTrashEntityThunk = createAsyncThunk(
  'trashEntity/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/entities`, config)
      return data

    } catch (error) {
      console.log(error,"error");
      return error

    }
  }
)

//restore trash entity
export const restoreEntityThunk = createAsyncThunk(
  'update/trashEntity',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/entities`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

//restore trash entity
export const removeTrashEntityThunk = createAsyncThunk(
  'remove/trashEntity',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.delete(`${api_url}/entities`, {
        data: { itemIds },
        headers: {
          'Content-Type': 'application/json'
        },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

// get single entity thunk
export const getSingleEntityThunk = createAsyncThunk(
  'entitySingle/get',
  async ({ headers,_id }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
    }
    try {
      const data = await axios.get(`${api_url}/entities/${_id}`, config)
      return data

    } catch (error) {
      return error

    }
  }
)

// delete group thunk
export const deleteEntityThunk = createAsyncThunk(
  'entity/delete',
  async ({ headers,_id, params }) => {

    const config = {
      headers: {
        "Authorization":  headers.Authorization
      },
      params: params
    }

    try {
      const data = await axios.delete(`${api_url}/entities/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }


  }
)
// delete group thunk
export const getEntityDocumentsThunk = createAsyncThunk(
  'documentEntity/get',
  async ({ headers,_id }) => {

    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/entities/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }
  }
)

export const updateEntityThunk = createAsyncThunk(
  'entity/update',
  async ({ headers, body, _id }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/entities/${_id}`, body, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
