import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`

//  document  add thunk
export const addTagsThunk = createAsyncThunk(
  "tags/add",
  async ({ body, headers }, { rejectWithValue }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/tags`, body, config);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

// get group thunk
export const getTagsThunk = createAsyncThunk(
  "tags/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/tags`, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);

// delete group thunk
export const deleteTagsThunk = createAsyncThunk(
  "tags/delete",
  async ({ headers, _id }) => {
    console.log({ headers, _id });
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/tags/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
