import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;
//  document  add thunk
export const addTaskThunk = createAsyncThunk(
  "tasks/add",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(body, "body", config, "config");
    try {
      const data = await axios.post(`${api_url}/tasks`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get group thunk
export const getTaskThunk = createAsyncThunk(
  "tasks/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/tasks`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
export const getSingleTaskThunk = createAsyncThunk(
  "task/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/tasks/${_id}`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// delete group thunk
export const deleterTaskThunk = createAsyncThunk(
  "tasks/delete",
  async ({ headers, _id,params }) => {
    console.log({headers,_id,params});
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params:params
    };
    try {
      const data = await axios.delete(`${api_url}/tasks/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);

