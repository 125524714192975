import { createSlice } from "@reduxjs/toolkit";
import { addRequirementTypesThunk, deleteRequirementTypesThunk, getRequirementTypesThunk } from "./requirementTypesThunk";
import { handleOopsError, handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  requirementsTypes: [],
  requirementsTypesOptions: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,

}

const requirementsTypesSlice = createSlice({
  name: "requirementsTypes",
  initialState,
  reducers: {
    changeLimit: (state, action) => {

      state.limit = action.payload;
    },
    changePage: (state, action) => {

      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRequirementTypesThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data
        state.requirementsTypes = { ...state.requirementsTypes, doc }
        handleSuccess('Requirement type saved successfuly')
      })
      .addCase(addRequirementTypesThunk.pending, (state, action) => {
        // console.log({ action });


      })


      .addCase(addRequirementTypesThunk.rejected, (state, action) => {



      })

      //  get document
      .addCase(getRequirementTypesThunk.fulfilled, (state, action) => {
        // console.log("get documents slice", action.payload);
        const { data } = action.payload.data.data
        if (data.length > 0) {
          let tempOptions = []
          // eslint-disable-next-line array-callback-return
          data.map(uItem => {

            tempOptions.push({ value: uItem._id, label: uItem.title })
          })
          state.requirementsTypesOptions = [...tempOptions]
          state.requirementsTypes = { ...state.requirementsTypes, data }
          state.total = action?.payload?.data?.total
          state.result = action?.payload?.data?.results
        }




      })
      .addCase(getRequirementTypesThunk.pending, (state, action) => {
        // console.log(action)

      })

      .addCase(getRequirementTypesThunk.rejected, (state, action) => {
        // console.log(action)
      })

  }
})



export const { changeLimit, changePage } = requirementsTypesSlice.actions
export default requirementsTypesSlice.reducer;