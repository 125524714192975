import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";


const api_url = process.env.REACT_APP_API_URL_DEV  ;

// get jurisdiction entity by user 
export const getJurisdictionsByUser = createAsyncThunk(
  'jurisdictionsByUser/get',
  async ({ headers,params }) => {
    console.log(params ,"partmas");
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/entities`, config)
      return data

    } catch (error) {
   
      return error

    }
  }
)