import { createAsyncThunk } from "@reduxjs/toolkit"
import  axios  from 'axios';
const api_url = process.env.REACT_APP_API_URL_DEV  ;

// get ownership thunk
export const getParentAndSubsidiariesThunk = createAsyncThunk(
    'parentSubsidiaries/get',
    async ({ headers,params}) => {
      const config = {
        headers: {
          "Authorization": headers.Authorization
        },
        params:params
      }
      try {
          const data = await axios.get(`${api_url}/ownership`, config)
         
          return data
  
      } catch (error) {
      
        return error
  
      }
    }
  )