import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../../utils/toastMessage";
import {
  getTrashDocumentsThunk,
  restoreTrashDocumentsThunk,
  removeTrashDocumentsThunk,
  getTrashRequirementsThunk,
  restoreTrashRequirementsThunk,
  removeTrashRequirementsThunk,
  getTrashReportsThunk,
  restoreTrashReportsThunk,
  removeTrashReportsThunk,
  getTrashTaskThunk,
  restoreTrashTaskThunk,
  removeTrashTaskThunk,
  getTrashNotesThunk,
  restoreTrashNotesThunk,
  removeTrashNotesThunk,
  getTrashPeoplesThunk,
  restoreTrashPeoplesThunk,
  removeTrashPeoplesThunk,
  getTrashOwnershipsThunk,
  restoreTrashOwnershipsThunk,
  removeTrashOwnershipsThunk,
  getTrashAgentsThunk,
  restoreTrashAgentsThunk,
  removeTrashAgentsThunk,
  getTrashUnrelatedEntitiesThunk,
  getTrashContactsThunk,
  restoreTrashContactsThunk,
  removeTrashContactsThunk,
} from "./trashThunk";

const initialState = {
  error: "",
  page: 1,
  limit: 10,
  total: 0,
  loading: false,
  //Trashed documents
  trashDocuments: [],
  trashDocPage: 1,
  trashDocLimit: 10,
  trashDocTotal: 0,

  //Trashed Requirements
  trashRequirements: [],
  trashReqPage: 1,
  trashReqLimit: 10,
  trashReqTotal: 0,

  //Trashed Reports
  trashReports: [],
  trashRepPage: 1,
  trashRepLimit: 10,
  trashRepTotal: 0,

  //Trashed Task
  trashTasks: [],
  trashTaskPage: 1,
  trashTaskLimit: 10,
  trashTaskTotal: 0,

  //Trashed Notes
  trashNotes: [],
  trashNotePage: 1,
  trashNoteLimit: 10,
  trashNoteTotal: 0,

  trashPeoples: [],
  trashPeoplePage: 1,
  trashPeopleLimit: 10,
  trashPeopleTotal: 0,

  trashOwnerships: [],
  trashOwnershipPage: 1,
  trashOwnershipLimit: 10,
  trashOwnershipTotal: 0,

  trashAgents: [],
  trashAgentPage: 1,
  trashAgentLimit: 10,
  trashAgentTotal: 0,

  trashUnrelatedEntities: [],
  trashUnrelatedEntityPage: 1,
  trashUnrelatedEntityLimit: 10,
  trashUnrelatedEntityTotal: 0,


  trashContacts: [],
  trashContactsPage: 1,
  trashContactsLimit: 10,
  trashContactsTotal: 0,
};


const trashSlice = createSlice({
  name: "trash",
  initialState,
  reducers: {
    changeDocLimit: (state, action) => {
      state.trashDocLimit = action.payload;
    },
    changeDocPage: (state, action) => {
      state.trashDocPage = action.payload;
    },
    changeContactLimit: (state, action) => {
      state.trashContactsLimit = action.payload;
    },
    changeContactPage: (state, action) => {
      state.trashContactsPage = action.payload;
    },
    changeReqLimit: (state, action) => {
      state.trashReqLimit = action.payload;
    },
    changeReqPage: (state, action) => {
      state.trashReqPage = action.payload;
    },
    changeRepLimit: (state, action) => {
      state.trashRepLimit = action.payload;
    },
    changeRepPage: (state, action) => {
      state.trashRepPage = action.payload;
    },
    changeTaskLimit: (state, action) => {
      state.trashTaskLimit = action.payload;
    },
    changeTaskPage: (state, action) => {
      state.trashTaskPage = action.payload;
    },
    changeNoteLimit: (state, action) => {
      state.trashNoteLimit = action.payload;
    },
    changeNotePage: (state, action) => {
      state.trashNotePage = action.payload;
    },
    changePeopleLimit: (state, action) => {
      state.trashPeopleLimit = action.payload;
    },
    changePeoplePage: (state, action) => {
      state.trashPeoplePage = action.payload;
    },
    changeOwnershipLimit: (state, action) => {
      state.trashOwnershipLimit = action.payload;
    },
    changeOwnershipPage: (state, action) => {
      state.trashOwnershipPage = action.payload;
    },
    changeAgentLimit: (state, action) => {
      state.trashAgentLimit = action.payload;
    },
    changeAgentPage: (state, action) => {
      state.trashAgentPage = action.payload;
    },
    changeUnrelatedLimit: (state, action) => {
      state.trashUnrelatedEntityLimit = action.payload;
    },
    changeUnrelatedPage: (state, action) => {
      state.trashUnrelatedEntityPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //Trash section reducer
      .addCase(getTrashContactsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashContacts = action.payload?.data?.data;
          state.trashContactsTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashContactsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashContactsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashDocumentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashDocuments = action.payload.data.data;
          state.trashDocTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashDocumentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashDocumentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashRequirementsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashRequirements = action.payload.data.data;
          state.trashReqTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashRequirementsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashRequirementsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashReportsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashReports = action.payload.data.data;
          state.trashRepTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashReportsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashReportsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashTaskThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashTasks = action.payload.data.data;
          state.trashTaskTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashTaskThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashTaskThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashNotesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashNotes = action.payload.data.data;
          state.trashNoteTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashNotesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashNotesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashPeoplesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashPeoples = action.payload.data.data;
          state.trashPeopleTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashPeoplesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashPeoplesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashOwnershipsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashOwnerships = action.payload.data.data;
          state.trashOwnershipTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashOwnershipsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashOwnershipsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashAgentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashAgents = action.payload.data.data;
          state.trashAgentTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(restoreTrashAgentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          handleSuccess("Restored successfully!");
        }
      })

      .addCase(removeTrashAgentsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          handleSuccess("Removed successfully!!");
        }
      })

      .addCase(getTrashUnrelatedEntitiesThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.trashUnrelatedEntities = action.payload.data.data;
          state.trashUnrelatedEntityTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      });
  },
});

export const {
  changeDocLimit,
  changeDocPage,
  changeReqLimit,
  changeReqPage,
  changeRepLimit,
  changeRepPage,
  changeTaskLimit,
  changeTaskPage,
  changeNoteLimit,
  changeNotePage,
  changePeopleLimit,
  changePeoplePage,
  changeOwnershipLimit,
  changeOwnershipPage,
  changeAgentLimit,
  changeAgentPage,
  changeUnrelatedLimit,
  changeUnrelatedPage,
  changeContactLimit,
  changeContactPage
} = trashSlice.actions;

export default trashSlice.reducer;
