import { createSlice } from "@reduxjs/toolkit";

import { getParentAndSubsidiariesThunk } from "./parentSubsdiariesThunk";


const initialState = {
  parent: [],
  subsidiary: [],
  pageParent: 1,
  limitParent: 10,
  totalParent: 0,
  pageSubsidiary: 1,
  limitSubsidiary: 10,
  totalSubsidiary: 0,
  loading:false,

};

const parentAndSubsidiariesSlice = createSlice({
  name: "parentAndSubsidiaries",
  initialState,
  reducers: {
    changeSubsidiaryLimit: (state, action) => {
      state.limitSubsidiary = action.payload;
    },
    changeSubsidiaryPage: (state, action) => {
      state.pageSubsidiary = action.payload;
    },
    changeParentLimit: (state, action) => {
      state.limitParent = action.payload;
    },
    changeParentPage: (state, action) => {
      state.pageParent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
     .addCase(getParentAndSubsidiariesThunk.fulfilled, (state, action) => {
        state.loading = true;
       if(action.payload.status === 200){
         const {type}= action?.payload?.data;
         const{ownership} = action?.payload?.data?.data;
         console.log(action.payload ,"payload");
         if(type === 'Parent Ownership'){
          state.parent = [...ownership]
          state.totalParent = action?.payload?.data?.total;
         } else{
          state.subsidiary= [...ownership]
          state.totalSubsidiary = action?.payload?.data?.total;
         }


      }
       
      })

    
  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const { changeSubsidiaryLimit, changeSubsidiaryPage, changeParentLimit, changeParentPage } =
parentAndSubsidiariesSlice.actions;
export default parentAndSubsidiariesSlice.reducer;
