import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL_DEV;

// get entity thunk
export const getDashboardThunk = createAsyncThunk(
  "dashboard/get",
  async ({ headers, params }) => {
    //console.log(params,"parms");
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/dashboard`, config);
      //console.log(data,"data");
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getDashboardReportsThunk = createAsyncThunk(
  "dashboardAnnualReport/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/requirements`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getDashboardOtherRequirementsThunk = createAsyncThunk(
  "dashboardOtherRequirements/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/requirements`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
// get all alerts
export const getDashboardAlertsThunk = createAsyncThunk(
  "dashboardAlerts/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/alert`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
// get all task
export const getDashboardTaskThunk = createAsyncThunk(
  "dashboardTask/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/tasks`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get all Billing
export const getDashboardBillingThunk = createAsyncThunk(
  "dashboardBilling/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/orders`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get group thunk
export const getUsersAnswerAverageThunk = createAsyncThunk(
  "users/getUsersAnswerAverage",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/useranswers/average`, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);

// get group thunk
export const getUsersAnswersDetailsThunk = createAsyncThunk(
  "users/getUsersAnswerDetails",
  async ({ headers, params,_id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
      _id,
    };
    try {
      const data = await axios.get(`${api_url}/useranswers/${_id}`, config);

      return data;
    } catch (error) {
      return error;
    }
  }
);
