import { createSlice } from "@reduxjs/toolkit";
import { handleError, handleSuccess } from "../../../../utils/toastMessage";
import { getEntityTaskThunk, updateTaskThunk } from "./entityThunk";

const initialState = {
  //Specific to entity in-progress
  entityTaskInProgress: [],
  entityPageInProgress: 1,
  entityTotalInProgress: 0,
  entityLimitInProgress: 10,
  entityPageCountInProgress: 1,
  //Specific to entity canceled
  entityTaskCanceled: [],
  entityPageCanceled: 1,
  entityTaskLimitCanceled: 10,
  entityTotalCanceled: 0,
  entityPageCountCanceled: 1,
  //Specific to entity Completed
  entityTaskCompleted: [],
  entityPageCompleted: 1,
  entityTaskLimitCompleted: 10,
  entityTotalCompleted: 0,
  entityPageCountCompleted: 1,
  //Specific to entity Overdue
  entityTaskOverdue: [],
  entityPageOverdue: 1,
  entityTaskLimitOverdue: 10,
  entityTotalOverdue: 0,
  entityPageCountOverdue: 1,
};

const tasksSlice = createSlice({
  name: "entityTask",
  initialState,
  reducers: {
    //In progress tab actions
    changeLimitTabInProgress: (state, action) => {
      state.entityLimitInProgress = action.payload;
    },
    changePageTabInProgress: (state, action) => {
      state.entityPageInProgress = action.payload;
    },
    changeLimitTabCompleted: (state, action) => {
      state.entityTaskLimitCompleted = action.payload;
    },
    //Completed tab actions
    changePageTabCompleted: (state, action) => {
      state.entityPageCompleted = action.payload;
    },
    //Canceled tab actions
    changeLimitTabCanceled: (state, action) => {
      state.entityTaskLimitCompleted = action.payload;
    },
    changePageTabCanceled: (state, action) => {
      state.entityPageCompleted = action.payload;
    },
    changeLimitTabOverdue: (state, action) => {
      state.entityTaskLimitOverdue = action.payload;
    },
    //Overdue tab actions
    changePageTabOverdue: (state, action) => {
      state.entityPageOverdue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityTaskThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action.payload,"payload__");
        const { task } = action.payload?.data?.data;
        if (task.length > 0) {
          let tempCompleted = [];
          let tempItemProgress = [];
          let tempItemCanceled = [];
          let tempItemOverdue = [];
          // eslint-disable-next-line array-callback-return
          task.map((item) => {
            if (item.status === "In-progress") {
              tempItemProgress.push(item);

              state.entityTotalInProgress = action?.payload?.data?.total;
              const pageCounter = Math.ceil(
                state.entityTotal / state.entityLimit
              );
              state.entityPageCountInProgress = pageCounter;
            } else if (item.status === "Cancelled") {
              tempItemCanceled.push(item);

              state.entityTotalCanceled = action?.payload?.data?.total;
              const pageCounter = Math.ceil(
                state.entityTotal / state.entityLimit
              );
              state.entityPageCountCanceled = pageCounter;
            } else if (item.status === "Overdue") {
              tempItemOverdue.push(item);
              state.entityTotalOverdue = action?.payload?.data?.total;
            } else {
              tempCompleted.push(item);
              state.entityTotalCompleted = action?.payload?.data?.total;
              const pageCounter = Math.ceil(
                state.entityTotal / state.entityLimit
              );
              state.entityPageCountCompleted = pageCounter;
            }
          });
          state.entityTaskInProgress = [...tempItemProgress];
          state.entityTaskCompleted = [...tempCompleted];
          state.entityTaskCanceled = [...tempItemCanceled];
          state.entityTaskOverdue = [...tempItemOverdue];
        }
      })
      //On update success
      .addCase(updateTaskThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          // const {entityRequirement} = action.payload?.data?.data;
          state.isLoading = false;
          handleSuccess("Task updated successfully!");
        } else {
          state.isLoading = false;
          const { message } = action.payload?.response?.data;
          handleError(message)

        }
      });
  },
});

export const {
  changePageTabCanceled,
  changeLimitTabCanceled,
  changeLimitTabInProgress,
  changePageTabInProgress,
  changeLimitTabCompleted,
  changePageTabCompleted,
  changeLimitTabOverdue,
  changePageTabOverdue,
} = tasksSlice.actions;
export default tasksSlice.reducer;
