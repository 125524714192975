import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError } from "../../../../utils/toastMessage";
import { getAlertsThunk } from "./alertsThunk";

const initialState = {
  upcoming: [],
  previous: [],
  pageUpcoming: 1,
  limitUpcoming: 10,
  totalUpcoming: 0,
  pagePrevious: 1,
  limitPrevious: 10,
  totalPrevious: 0,
  loading: false,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    changeUpcomingLimit: (state, action) => {
      state.limitUpcoming = action.payload;
    },
    changUpcomingPage: (state, action) => {
      state.pageUpcoming = action.payload;
    },
    changePreviousLimit: (state, action) => {
      state.limitPrevious = action.payload;
    },
    changePreviousPage: (state, action) => {
      state.pagePrevious = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //  get alerts side effects
      .addCase(getAlertsThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.status === 200) {
          if (action.payload.params.status === "upcoming") {

            const { alerts } = action.payload?.data?.data?.data;

            if (Array.isArray(alerts) && alerts.length > 0) {
              state.upcoming = [...alerts];
              state.totalUpcoming = action.payload.data.data.total;
            }
          } else if (action.payload.params.status === "previous") {
            const { alerts } = action.payload?.data?.data?.data;

            if (Array.isArray(alerts) && alerts.length > 0) {
              state.previous = [...alerts];
              state.totalUpcoming = action.payload.data.data.total;
            }
          }
        }

      })

  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const {
  changUpcomingPage,
  changeUpcomingLimit,
  changePreviousPage,
  changePreviousLimit,
} = alertsSlice.actions;
export default alertsSlice.reducer;
