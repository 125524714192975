import { createSlice } from "@reduxjs/toolkit"; 
import { addOrganisationThunk, deleteOrganisationThunk, getOrganisationThunk } from "./organisationThunk";
import { handleOopsError, handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  organization: {},
  organizations: [],
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
}

const organisationSlice=createSlice({
  name:"organisation",
  initialState,
  reducers:{
    changeLimitOrganization: (state, action) => {
      state.limit = action.payload;
     },
     changePageOrganization: (state, action) => {
      state.page = action.payload;
     }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(addOrganisationThunk.fulfilled, (state, action) => {
      const { doc } = action.payload.data.data
      state.organizations={...state.organizations,doc}
      handleSuccess('Organization type saved successfuly')
    })
    .addCase(addOrganisationThunk.pending, (state, action) => {
     state.loading = true;
    
     
    })


    .addCase(addOrganisationThunk.rejected, (state, action) => {
     //here will show toast error message
 

    })

   //  get document
   .addCase(getOrganisationThunk.fulfilled, (state, action) => {
    const {data} = action.payload.data.data
    // console.log("======>",data);
    state.organizations = {...state.organizations,data}
    state.total = action?.payload?.data?.total
    state.result = action?.payload?.data?.results
 



  })
  .addCase(getOrganisationThunk.pending, (state, action) => {     
    // console.log(action)
    state.loading = true;


  })

  .addCase(getOrganisationThunk.rejected, (state, action) => {
    // console.log(action)

  })





  .addCase(deleteOrganisationThunk.fulfilled, (state, action) => {
    // console.log("delete api", action.payload);
    // state.groups.filter((item) => item.id !== action.payload._id)
  })


  }
})

export const { changeLimitOrganization, changePageOrganization } = organisationSlice.actions


export default organisationSlice.reducer;