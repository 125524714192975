import { createSlice } from "@reduxjs/toolkit";
// import { addDocumentTypeThunk, deleteDocumentTypeThunk, getDocumentTypeThunk } from "./documentTypeThunk";
import {
  addUsersQuestionsThunk,
  addUsersThunk,
  getCheckUserAnswersThunk,
  getQuestionsThunk,
  getUsersThunk,
  updateUsersThunk,
  quickbookAuthenticateThunk,
  quickbookRefreshTokenThunk,
} from "./usersThunk";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";

const initialState = {
  user: {},
  users: [],
  userOptions: [],
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
  questions: [],
  quickbookCompanyInfo: localStorage.getItem("quickbookdata")
    ? JSON.parse(localStorage.getItem("quickbookdata")).companyInfo?.CompanyInfo
    : {},
  quickbookUserInfo: localStorage.getItem("quickbookdata")
    ? JSON.parse(localStorage.getItem("quickbookdata")).userInfo
    : {},
  quickbookCompanyId: localStorage.getItem("quickbookdata")
    ? JSON.parse(localStorage.getItem("quickbookdata")).companyID
    : "",
  quickbookAccessToken: localStorage.getItem("quickbookdata")
    ? JSON.parse(localStorage.getItem("quickbookdata")).accessToken
    : "",
  quickbookRefreshToken: localStorage.getItem("quickbookdata")
    ? JSON.parse(localStorage.getItem("quickbookdata")).refreshToken
    : "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUsersThunk.fulfilled, (state, action) => {
        if (
          action?.payload != undefined &&
          (action?.payload?.status === 200 || action?.payload?.status === 201)
        ) {
          const { doc } = action?.payload?.data?.data;
          state.users = { ...state.users, doc };
          handleSuccess("User detail saved successfuly");
        }
      })
      .addCase(addUsersQuestionsThunk.fulfilled, (state, action) => {
        if (
          action?.payload != undefined &&
          (action?.payload?.status === 200 || action?.payload?.status === 201)
        ) {
          // const { doc } = action?.payload?.data?.data;
          // state.users = { ...state.users, doc };
          handleSuccess("User answers saved successfuly");
        }
      })
      .addCase(getQuestionsThunk.fulfilled, (state, action) => {
        if (action?.payload != undefined && action?.payload?.status === 200) {
          state.questions = action?.payload?.data?.data;
        }
      })

      // .addCase(addUsersThunk.rejected, (state, action) => {

      // })

      //  get document
      .addCase(getUsersThunk.fulfilled, (state, action) => {
        const { users } = action?.payload?.data?.data;
        if (action?.payload?.status === 200) {
          let tempOptions = [];
          if (users?.length > 0) {
            users?.map((item) => {
              tempOptions?.push({ label: item.firstname, value: item._id });
            });
            state.userOptions = [...tempOptions];
            state.users = { ...state.documents, users };
            state.total = action?.payload?.data?.total;
            state.result = action?.payload?.data?.results;
          }
        }
      })

      .addCase(getCheckUserAnswersThunk.fulfilled, (state, action) => {})

      // .addCase(deleteDocumentTypeThunk.fulfilled, (state, action) => {
      //   // console.log("delete api", action.payload);
      //   // state.groups.filter((item) => item.id !== action.payload._id)
      // })

      .addCase(updateUsersThunk.fulfilled, (state, action) => {
        if (
          action?.payload != undefined &&
          (action?.payload?.status === 200 || action?.payload?.status === 201)
        ) {
          //const { doc } = action.payload.data.data
          //state.users = { ...state.users, doc }
          handleSuccess("Updated successfully");
        }
      })

      .addCase(quickbookAuthenticateThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.quickbookCompanyInfo =
            action?.payload?.data?.companyInfo?.CompanyInfo;
          state.quickbookUserInfo = action?.payload?.data?.userInfo;
          state.quickbookCompanyId = action?.payload?.data?.companyID;
          state.quickbookAccessToken = action?.payload?.data?.accessToken;
          state.quickbookRefreshToken = action?.payload?.data?.refreshToken;
        }
      })

      .addCase(quickbookRefreshTokenThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          var data = localStorage.getItem("quickbookdata")
            ? JSON.parse(localStorage.getItem("quickbookdata"))
            : "";
          state.quickbookAccessToken =
            action?.payload?.data?.data?.token?.access_token;
          data.accessToken = action?.payload?.data?.data?.token?.access_token;
          localStorage.setItem("quickbookdata", JSON.stringify(data));
        }
      });
  },
});

export const { changeLimit, changePage } = usersSlice.actions;
export default usersSlice.reducer;
