import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";
import { addContactThunk, deleteContactThunk, getAllContactThunk } from "./contactThunk";

const initialState = {
  contacts: [],
  contactTotal: 0,
  page: 1,
  limit: 10,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addContactThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("Contact is added successfully!");
        } else {
          handleOopsError();
        }
      })

      .addCase(deleteContactThunk.fulfilled, (state, action) => {
        if (action.payload.data.status === 201) {
          handleSuccess("Contact removed successfully!");
        } else {
          handleOopsError();
        }
      })

      .addCase(getAllContactThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
            state.contactTotal = action.payload?.data?.total;
            state.contacts = action?.payload?.data?.data;
        }
      });
  },
});

export const {
  changeLimit,
  changePage,
} = contactSlice.actions;

export default contactSlice.reducer;
