import ReactDashboardrequirments from "react";
import { Button } from "react-bootstrap";

const Sidebartoggle = () => {
    function sidebarclass() {
        document.getElementById('admin_view').classList.add('activesidebar');
    }
    return (
        <>
         <Button className="btn p-0 border-0 toggle_btn d-block d-xxl-none  d-xl-none d-lg-none" variant="unset" onClick={sidebarclass}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_202_6833)">
                        <path d="M12.3333 11.3333C12.8855 11.3333 13.3333 11.781 13.3333 12.3333V12.3333C13.3333 12.8856 12.8855 13.3333 12.3333 13.3333L3.66659 13.3333C3.1143 13.3333 2.66659 12.8856 2.66659 12.3333V12.3333C2.66659 11.781 3.1143 11.3333 3.66659 11.3333L12.3333 11.3333ZM5.66659 3.33331C6.21887 3.33331 6.66659 3.78103 6.66659 4.33331V4.33331C6.66659 4.8856 6.21887 5.33331 5.66659 5.33331L3.66659 5.33331C3.1143 5.33331 2.66659 4.8856 2.66659 4.33331V4.33331C2.66659 3.78103 3.1143 3.33331 3.66659 3.33331L5.66659 3.33331ZM8.99992 7.33331C9.5522 7.33331 9.99992 7.78103 9.99992 8.33331V8.33331C9.99992 8.8856 9.5522 9.33331 8.99992 9.33331L3.66659 9.33331C3.1143 9.33331 2.66659 8.8856 2.66659 8.33331V8.33331C2.66659 7.78103 3.1143 7.33331 3.66659 7.33331L8.99992 7.33331Z" fill="#107FE7" />
                    </g>
                    <defs>
                        <clipPath id="clip0_202_6833">
                            <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
                        </clipPath>
                    </defs>
                </svg>
        </Button>
        </>
    )
}
export default Sidebartoggle;