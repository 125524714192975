import { createSlice } from "@reduxjs/toolkit";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";

import { addRegisterAgentThunk, deleteRegisterAgentThunk, getRegisterAgentThunk } from './registerAgentThunk';

const initialState = {
  registerAgent: [],
  registerAgentTotal: 0,
  agentOptions: [],
  registerAgents: [],
  page: 1,
  limit: 10,
};

const registerAgentSlice = createSlice({
  name: "registerAgent",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRegisterAgentThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
            const {doc}= action.payload.data.data;
            let newOption = {value: doc._id , label: doc.fullName}
            state.registerAgent = [...state.registerAgent  ,newOption]
            handleSuccess("Agent added successfully!");
        } else {
          handleOopsError();
        }
      })
      .addCase(getRegisterAgentThunk.fulfilled, (state, action) => {
        const {agents} = action.payload.data?.data;
        if (action?.payload?.status === 200) {
            let tempOptions = [];
              if(agents.length > 0){
                agents.map((item)=>{
                tempOptions.push({ label: item.fullName, value: item._id });
              });
            }
            state.agentOptions = [...tempOptions];
            state.registerAgentTotal = action.payload.data?.total;
            state.registerAgents = action?.payload?.data?.data;
        } 
      })

      .addCase(deleteRegisterAgentThunk.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 201) {
          handleSuccess("Agent removed successfully!");
        } else {
          handleOopsError();
        }
      });
  },
});

export const {
  changeLimit,
  changePage,
} = registerAgentSlice.actions;

export default registerAgentSlice.reducer;
