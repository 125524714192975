

export const authSelector = (state) => {
  const {
    isLoggedIn,
    isLoggingStatus,
    isLoggingError,
    loggingErrorMsg,
    userData,
    isRegisterSuccess,
    isRegisterStatus,
    isRegisterError,
    registerErrorMsg,
    token,
    isUserVerified,
    forgotPasswordStatus,
    isForgotPasswordError,
    forgotPasswordErrorMsg,
    isForgotPasswordSuccess,
    otpVerified,
    responseOtp,
    isLoading


  } = state.auth
  return {
    isLoggedIn,
    isLoggingStatus,
    isLoggingError,
    loggingErrorMsg,
    userData,
    isRegisterSuccess,
    isRegisterStatus,
    isRegisterError,
    registerErrorMsg,
    token,
    isUserVerified,
    forgotPasswordStatus,
    isForgotPasswordError,
    forgotPasswordErrorMsg,
    isForgotPasswordSuccess,
    otpVerified,
    responseOtp,
    isLoading
  }
}