export const requirementsTypesSelector = (state) => {
  const {
    requirementsTypes,
    requirementsTypesOptions,
    currentPage,
    numberOfPages,
    error,
    loading,
    total,
    limit,
    page,
    result,
  } = state.requirementsTypes
  return {
    requirementsTypes,
    requirementsTypesOptions,
    currentPage,
    numberOfPages,
    error,
    loading,
    total,
    limit,
    page,
    result,
  }
}