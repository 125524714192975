import { createSlice } from "@reduxjs/toolkit";

import { handleOopsError, handleSuccess } from "../../utils/toastMessage";
import {
  addTaskThunk,
  deleterTaskThunk,

  getTaskThunk,
} from "./taskThunk";
const initialState = {
  tasks: [],
  total: 0,
  isLoading: false,
  limit: 10,
  page: 1,
  result: 0,
  
  
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeEntityLimit:(state , action)=>{
      state.entityLimit= action.payload;
    },
    changeEntityPage: (state, action)=>{
      state.entityPage = action.payload;

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTaskThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("New task added successfully");
        }
      })
      .addCase(addTaskThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addTaskThunk.rejected, (state, action) => {
        state.isLoading = true;
      handleOopsError();
      })

      //  get task
      .addCase(getTaskThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const {task } = action.payload.data.data;
        // console.log(data);
        if (task) {
          state.tasks = [...task];
          state.total = action?.payload?.data?.total;
          state.result = action?.payload?.data?.results;
        }
      })
      .addCase(getTaskThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getTaskThunk.rejected, (state, action) => {
        // console.log(action)
        state.isLoading = false;
       handleOopsError()
      })

      .addCase(deleterTaskThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        handleSuccess("Task removed successfully!")
      
      })
      .addCase(deleterTaskThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleterTaskThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
  
  },
})

export const { changeLimit, changePage ,changeEntityLimit , changeEntityPage } = tasksSlice.actions;
export default tasksSlice.reducer;
