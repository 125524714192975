import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const addEntityInformationThunk = createAsyncThunk(
  "Information/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/informations`, body, config);

      return data;
    } catch (error) {
      return error.response;
    }
  }
);

// get Information thunk
export const getEntityInformationThunk = createAsyncThunk(
  "Information/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      if (_id) {
        const data = await axios.get(
          `${api_url}/entities/${_id}/informations`,
          config
        );
        return data;
      } else {
        const data = await axios.get(`${api_url}/informations`, config);
        return data;
      }


      const data = await axios.get(`${api_url}/informations/${_id}`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// delete Information thunk
export const deleteEntityInformationThunk = createAsyncThunk(
  "Information/delete",
  async ({ headers, _id, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.delete(`${api_url}/informations/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
//document  update thunk
export const updateInformationThunk = createAsyncThunk(
  "Information/update",
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(
        `${api_url}/informations/${_id}`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);
