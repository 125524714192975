import { createSlice } from "@reduxjs/toolkit";
import { addCustomFieldsThunk, deleteCustomFieldsThunk, getCustomFieldsThunk } from "./customThunk";
import { handleOopsError, handleSuccess } from "../../../../utils/toastMessage"; 

const initialState = {

 
  // data: [],
  customField: {},
  customFields: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
}

const customFieldsSlice=createSlice({
  name:"customFields",
  initialState,
  reducers:{
    changeLimit: (state, action) => {

      state.limit = action.payload;
    },
    changePage: (state, action) => {

      state.page = action.payload;
    }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(addCustomFieldsThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data   
        state.customFields={...state.customFields,doc}
        handleSuccess('Custom field details saved successfuly')
    })
    .addCase(addCustomFieldsThunk.pending, (state, action) => {
      // console.log({ action });
    
     
    })


    .addCase(addCustomFieldsThunk.rejected, (state, action) => {
     
 

    })

   //  get document
   .addCase(getCustomFieldsThunk.fulfilled, (state, action) => {
    // console.log("get documents slice", action.payload);
    const {data} = action.payload.data.data
    // console.log("======>",data);
    state.customFields = {...state.customFields,data}
    state.total = action?.payload?.data?.total
    state.result = action?.payload?.data?.results



  })
  .addCase(getCustomFieldsThunk.pending, (state, action) => {     
    // console.log(action)

  })

  .addCase(getCustomFieldsThunk.rejected, (state, action) => {
    // console.log(action)
  })





  .addCase(deleteCustomFieldsThunk.fulfilled, (state, action) => {
    // console.log("delete api", action.payload);
    // state.groups.filter((item) => item.id !== action.payload._id)
  })


  }
})


export const { changeLimit, changePage } = customFieldsSlice.actions
export default customFieldsSlice.reducer;