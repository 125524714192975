import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`

//  group add thunk
export const addUsersThunk = createAsyncThunk(
  "users/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/users`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

//  group add thunk
export const addUsersQuestionsThunk = createAsyncThunk(
  "users/addUsersQuestions",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(
        `${api_url}/useranswers/useranswers`,
        body,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

// get group thunk
export const getUsersThunk = createAsyncThunk(
  "users/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/users`, config);

      return data;
    } catch (error) {
      // return error
    }
  }
);
// get group thunk
export const getQuestionsThunk = createAsyncThunk(
  "users/questions",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/questions`, config);

      return data;
    } catch (error) {
      // return error
    }
  }
);
// get group thunk
export const getCheckUserAnswersThunk = createAsyncThunk(
  "users/getCheckUserAnswers",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(
        `${api_url}/useranswers/checkUserAnswerCount/${_id}`,
        config
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);
// delete group thunk
export const deleteUsersThunk = createAsyncThunk(
  "users/delete",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/users/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);

// update group thunk
export const updateUsersThunk = createAsyncThunk(
  "users/update",
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/users/${_id}`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserThunk = createAsyncThunk(
  "user/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/users/${_id}`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const sendUserInvitationThunk = createAsyncThunk(
  "sendinvitation/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(
        `${api_url}/users/sendinvitation/${_id}`,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookAuthorizeThunk = createAsyncThunk(
  "quickbook/authorizeUrl/get",
  async ({ headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(
        `${api_url}/quickbook/authorizeUrl/`,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const quickbookAuthenticateThunk = createAsyncThunk(
  "quickbook/authenticate",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(
        `${api_url}/quickbook/authenticate`,
        body,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const quickbookRefreshTokenThunk = createAsyncThunk(
  "quickbook/refreshingToken",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(
        `${api_url}/quickbook/refreshingToken`,
        body,
        config
      );
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
