import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const addEntityNoteThunk = createAsyncThunk(
  "notes/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/notes`, body, config);

      return data;
    } catch (error) {
      return error.response;
    }
  }
);

// get notes thunk
export const getEntityNotesThunk = createAsyncThunk(
  "notes/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      if (_id) {
        const data = await axios.get(
          `${api_url}/entities/${_id}/notes`,
          config
        );
        return data;
      } else {
        const data = await axios.get(`${api_url}/notes`, config);
        return data;
      }
    } catch (error) {
      return error;
    }
  }
);

// delete notes thunk
export const deleteEntityNoteThunk = createAsyncThunk(
  "notes/delete",
  async ({ headers, _id, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.delete(`${api_url}/notes/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
//document  update thunk
export const updateNotesThunk = createAsyncThunk(
  "notes/update",
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/notes/${_id}`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
