import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addEntityNoteThunk,
  deleteEntityNoteThunk,
  getEntityNotesThunk,
} from "./notesThunk";
const initialState = {
  notesList: [],
  notesLimit: 10,
  notesPage: 1,
  notesTotal:0,
  loading: false,
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    changeNotesLimit: (state, action) => {
      state.notesLimit = action.payload;
    },
    changeNotesPage: (state, action) => {
      state.notesPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEntityNoteThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          toast.success("Notes added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
          const { doc } = action.payload.data.data;
          state.loading = false;

          state.entity = { ...state.entity, doc };
        } else {
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })

      .addCase(addEntityNoteThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
        toast.error("Oops! Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      })

      .addCase(deleteEntityNoteThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data.status === 201) {
          toast.success("Removed successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else {
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(deleteEntityNoteThunk.rejected, (state, action) => {
        state.loading = false;
        toast.error("Oops! Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      })

      .addCase(getEntityNotesThunk.fulfilled, (state, action) => {
     
     if(action.payload.status === 200){
        const{notes} = action.payload?.data?.data;
        state.notesList =[...notes];
        state.notesTotal= action?.payload?.data?.total
      }

      
      })
      .addCase(getEntityNotesThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntityNotesThunk.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// export const { refreshStatus } = groupsSlice.actions;
export const { changeNotesLimit, changeNotesPage } = notesSlice.actions;
export default notesSlice.reducer;
