import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
 
import { addEntityInformationThunk, deleteEntityInformationThunk, getEntityInformationThunk } from "./informationThunk";
const initialState = {
  informations: [],
  informationLimit: 10,
  informationPage: 1,
  informationTotal:0,
  loading: false,
 
};

const informationSlice = createSlice({
  name: "informations",
  initialState,
  reducers: {
    changeInformationLimit: (state, action) => {
      state.informationLimit = action.payload;
    },
    changeInformationPage: (state, action) => {
      state.informationPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEntityInformationThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          toast.success("Notes added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
          const { doc } = action.payload.data.data;
          state.loading = false;

          state.informations = { ...state.informations, doc };
        } else {
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })

      .addCase(addEntityInformationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
        toast.error("Oops! Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      })

      .addCase(deleteEntityInformationThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data.status === 201) {
          toast.success("Removed successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        } else {
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(deleteEntityInformationThunk.rejected, (state, action) => {
        state.loading = false;
        toast.error("Oops! Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      })

      .addCase(getEntityInformationThunk.fulfilled, (state, action) => {
     console.log(action.payload?.data?.data);
     if(action.payload.status === 200){
        const{informations} = action.payload?.data?.data;
        state.informations =[...informations];
        state.informationTotal= action?.payload?.data?.total
      }

      
      })
      .addCase(getEntityInformationThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntityInformationThunk.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
 
export const { changeInformationLimit, changeInformationPage } = informationSlice.actions;
export default informationSlice.reducer;
