import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV ;

export const getCurrenciesThunk = createAsyncThunk(
  'currencies/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/currencies`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const getDeliveryPartnersThunk = createAsyncThunk(
  'deliveryPartners/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/deliverypartners`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const addCurrencyThunk = createAsyncThunk(
  'currencies/add',
  async ({ body, headers },{ rejectWithValue }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/currencies`, body, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const deleteCurrencyThunk = createAsyncThunk(
  'currencies/delete',
  async ({ headers,_id }) => {
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/currencies/${_id}`,config)
      return {data, _id}
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
)

export const addDeliveryPartnerThunk = createAsyncThunk(
  'deliveryPartners/add',
  async ({ body, headers },{ rejectWithValue }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/deliverypartners`, body, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const deleteDeliveryPartnerThunk = createAsyncThunk(
  'deliveryPartners/delete',
  async ({ headers,_id }) => {
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/deliverypartners/${_id}`,config)
      return {data, _id}
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
)