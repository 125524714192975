import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`

export const addDesignationThunk = createAsyncThunk(
  "designation/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/designation`, body, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getdesignationThunk = createAsyncThunk(
  "designation/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/designation`, config);
      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);
