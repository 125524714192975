import { createSlice } from "@reduxjs/toolkit";

import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";

import {
  addTaskCommentThunk,
  addTaskDocThunk,
  getTaskCommentThunk,
  getTaskDocumentsThunk,
  getTaskStatusThunk,
  addTaskStatusThunk,
  getCommentThunk
} from "./taskCommentThunk";
const initialState = {
  taskComments: [],
  taskDocuments: [],
  taskStatuses: [],

  total: 0,
  isLoading: false,
  limit: 10,
  page: 1,
  result: 0,
  // entityLimit,
  // entityPage
};

const tasksCommentSlice = createSlice({
  name: "taskComment",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeEntityLimit: (state, action) => {
      state.entityLimit = action.payload;
    },
    changeEntityPage: (state, action) => {
      state.entityPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTaskCommentThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("comment added successfully.");
          // state.taskComments = [
          //   ...state.taskComments,
          //   action?.payload?.data?.data?.comment,
          // ];
        }
      })
      .addCase(addTaskCommentThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addTaskCommentThunk.rejected, (state, action) => {
        state.isLoading = true;
        handleOopsError();
      })
      .addCase(addTaskDocThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("Document uploaded successfully.");
          // state.taskComments = [
          //   ...state.taskComments,
          //   action?.payload?.data?.data?.comment,
          // ];
        }
      })
      .addCase(addTaskDocThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addTaskDocThunk.rejected, (state, action) => {
        state.isLoading = true;
        handleOopsError();
      })
      //  get task
      .addCase(getTaskCommentThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { comment } = action?.payload?.data?.data;
        if (comment) {
          state.taskComments = [...comment];
          state.total = action?.payload?.data?.total;
          state.result = action?.payload?.data?.results;
        }
      })
      .addCase(getTaskCommentThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getTaskCommentThunk.rejected, (state, action) => {
        // console.log(action)
        state.isLoading = false;
        handleOopsError();
      })

      .addCase(getTaskDocumentsThunk.fulfilled, (state, action) => {
        state.taskDocuments = action?.payload?.data?.data?.documents;
        state.total = action?.payload?.data?.total;
        state.result = action?.payload?.data?.results;
      })

      .addCase(addTaskStatusThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          handleSuccess("status added successfully.");
        }
      })
      .addCase(addTaskStatusThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addTaskStatusThunk.rejected, (state, action) => {
        state.isLoading = true;
        handleOopsError();
      })

      .addCase(getTaskStatusThunk.fulfilled, (state, action) => {
        state.taskStatuses = action?.payload?.data?.data?.statuses;
      })

      .addCase(getCommentThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { comment } = action?.payload?.data?.data;
        if (comment) {
          state.taskComments = [...comment];
          state.total = action?.payload?.data?.total;
          state.result = action?.payload?.data?.results;
        }
      })
  },
});

export const { changeLimit, changePage, changeEntityLimit, changeEntityPage } =
  tasksCommentSlice.actions;
export default tasksCommentSlice.reducer;
