import { createSlice } from "@reduxjs/toolkit";
import {
  addPeoplesThunk,
  deletePeoplesThunk,
  getPeoplesEntityThunk,
  getPeoplesStatsThunk,
  getPeoplesThunk,
} from "./peopleThunk";
import { toast } from "react-toastify";

const initialState = {
  peoples: [],
  currentPage: 1,
  limit: 10,
  total: 0,
  error: "",
  loading: false,
  allpeople: [],
  peopletotal: 0,
  directorsStats: [],
  officersStats: [],
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    changePeoplePage: (state, action) => {
      state.currentPage = action.payload;
    },
    changePeopleLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPeoplesThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          toast.success("People added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
          const { entityPeople } = action?.payload?.data?.data;
          state.peoples = [...state.peoples, entityPeople];
        } else {
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(addPeoplesThunk.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(addPeoplesThunk.rejected, (state, action) => {})

      //  get peoples
      .addCase(getPeoplesEntityThunk.fulfilled, (state, action) => {
        const { data } = action.payload?.data?.data;

        if (action.payload.data.status === 200) {
          state.peoples = [...data.Peoples];
          state.total = action?.payload?.data?.data?.total;
        } else {
          //Show error message here
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(getPeoplesEntityThunk.pending, (state, action) => {
        // console.log(action)
      })

      .addCase(getPeoplesEntityThunk.rejected, (state, action) => {
        // console.log(action)
      })

      .addCase(getPeoplesStatsThunk.fulfilled, (state, action) => {
  
        state.directorsStats = action.payload.data.data.directors;
        state.officersStats = action.payload.data.data.officers;
      })

      .addCase(getPeoplesThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.allpeople = action.payload?.data?.data;
          state.peopletotal = action?.payload?.data?.total;
        } else {
          //Show error message here
          toast.error("Oops! Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      });
  },
});

export const { changePeoplePage, changePeopleLimit } = peopleSlice.actions;

export default peopleSlice.reducer;
