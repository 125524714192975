import { createSlice } from "@reduxjs/toolkit";
import { getEntityThunk } from "../Entity/entityThunk";
import { getJurisdictionsByUser } from "./jurisdicationByUserThunk";

const initialState = {
  jurisdictions: [],
  total: 0,
  limit: 10,
  page: 1,

};

const jurisdictionsByUserSlice = createSlice({
  name: "jurisdictionsByUser",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder



      .addCase(getJurisdictionsByUser.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          const { entities } = action?.payload?.data?.data;
          state.jurisdictions = [...entities];
          state.total = action.payload?.data?.total;

        }

      })


  },
});

export const { changeLimit, changePage } = jurisdictionsByUserSlice.actions;
export default jurisdictionsByUserSlice.reducer;
