import { createSlice } from "@reduxjs/toolkit";
import {
  addJursdictionsThunk,
  deleteJursdictionsThunk,
  getJursdictionsThunk,
} from "./jurisdictionThunk";
import { toast } from "react-toastify";
import { handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  jurisdictionsOptions: [],
  jurisdictions: [],
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
};

const jurisdictionsSlice = createSlice({
  name: "jurisdictions",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addJursdictionsThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          const { doc } = action.payload.data.data;
          let concat_label = doc.country + ":" + doc.state;
          //Modifying the option according to need
          let newObj = {
            value: doc._id,
            label: concat_label,
          };

          state.jurisdictionsOptions = [...state.jurisdictionsOptions, newObj];
          handleSuccess("New jurisdiction added successfully");
        } else {
          const { message } = action.payload;
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(addJursdictionsThunk.pending, (state, action) => {
      })

      .addCase(addJursdictionsThunk.rejected, (state, action) => { })
      .addCase(getJursdictionsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.jurisdictions = action?.payload?.data?.results;
          state.total = action?.payload?.data?.total;
          if (action?.payload?.data?.total > 0) {
            state.jurisdictionsOptions = action?.payload?.data?.results.map((item) => {
              let concat_label = item.country + ":" + item.state;
              let option = { value: item._id, label: concat_label };
              return option;
            })
          }
        }
      })
      .addCase(getJursdictionsThunk.pending, (state, action) => {

      })

      .addCase(deleteJursdictionsThunk.fulfilled, (state, action) => {

      });
  },
});

export const { changeLimit, changePage } = jurisdictionsSlice.actions;
export default jurisdictionsSlice.reducer;
