import { createSlice } from "@reduxjs/toolkit";
import { addGroupThunk, deleteGroupThunk, getGroup, getGroupThunk } from "./groupThunk";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";

const initialState = {

  groupsErrorMsg: "",
  groupsStatus: false,
  // data: [], 
  group: {},
  groups: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  groupOptions: [],
}

const groupsSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.currentPage = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addGroupThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data
        state.groupsStatus = true
        state.groups={...state.groups,doc}
        handleSuccess('Group detail saved successfuly')
      })
      .addCase(addGroupThunk.pending, (state, action) => {
        console.log({ action });
        state.groupsStatus = false
        // state.isGroup = "loading"
      })


      .addCase(addGroupThunk.rejected, (state, action) => {
        // state.isGroup = "rejected"
        state.groupsStatus = false
        console.log({action});

      })

      //  get group
      .addCase(getGroupThunk.fulfilled, (state, action) => {
        const {data} = action?.payload
        state.groups = {...state.groups,data}
        state.total = action?.payload?.data?.total;
        state.groupsStatus = true

        let tempOptions=[]     
        let datalist = data?.data?.data

        if(data?.total > 0){
          datalist?.forEach((item) => {
            tempOptions?.push({value:item._id , label:item.title });
          })
            
          state.groupOptions=[...tempOptions]
        }

      })
      .addCase(getGroupThunk.pending, (state, action) => {     
        // console.log(action)

      })

      .addCase(getGroupThunk.rejected, (state, action) => {
        // console.log(action)
      })



      // delete thunk
      // .addCase(deleteGroupThunk.fulfilled, (state, action) => {


      // })
      .addCase(deleteGroupThunk.fulfilled, (state, action) => {
        // console.log("delete api", action.payload);
        // state.groups.filter((item) => item.id !== action.payload._id)
      })
    // .addCase(deleteGroupThunk.fulfilled, (state, action) => {

    // })



    // update group thunk
    // .addCase(deleteGroupThunk.fulfilled, (state, action) => {

    // })
    // .addCase(deleteGroupThunk.fulfilled, (state, action) => {

    // })
    // .addCase(deleteGroupThunk.fulfilled, (state, action) => {

    // })


  }
})


export const { changePage, changeLimit } = groupsSlice.actions
export default groupsSlice.reducer;