import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Email is required."),
  password: Yup.string().required("Password is required."),
});
export const customerValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  firstname: Yup.string().required("Required!"),
  lastname: Yup.string().required("Required!"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 digits")
    .max(13, "Phone number can be at most 13 digits")
    .required("Required!"),
});

export const planValidationSchema = Yup.object({
  title: Yup.string()
    .required("Required.")
    .max(20, "Title must be at most 20 characters long.")
    .trim(),
  numbersOfUsers: Yup.number()
    .required("Required.")
    .integer("Number of users must be an integer.")
    .min(0, "Number of users cannot be negative."),
  numbersOfEntities: Yup.number()
    .required("Required.")
    .integer("Number of entities must be an integer.")
    .min(0, "Number of entities cannot be negative."),
  storage: Yup.number()
    .required("Required.")
    .positive("Storage must be a positive number.")
    .integer("Storage must be an integer.")

    .min(0, "Storage cannot be negative."),
  price: Yup.number()
    .required("Required.")
    .positive("Price must be a positive number.")

    .min(0, "Price cannot be negative."),
  planType: Yup.string().required("Required."),
});

export const forgotSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Email is required."),
});
export const verifyOtpSchema = Yup.object({
  phoneOtp: Yup.string("").required("OTP required."),
});
export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      "Password must meet the criteria of 8-16 alphanumeric character with min 1 special character."
    )
    .required("Password is required."),
  passwordConfirm: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
});


export const signUpSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Email is required."),
  password: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      "Password must meet the criteria of 8-16 alphanumeric character with min 1 special character."
    )
    .required("Password is required."),
  phone: Yup.string()
    .matches(/^\+[0-9]+$/, "Phone number must contain only digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number cannot exceed 15 digits")
    .required("Phone number is required"),
  passwordConfirm: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must be match."),
  firstname: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Firstname must contain only letters and spaces")
    .required("Firstname is required."),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

export const updateProfileModalSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Email is required."),
  firstname: Yup.string().required("Firstname is required."),
  phone: Yup.string("Phone must be a number type").required(
    "Phone number is required."
  ),
});

export const changePasswordModalSchema = Yup.object({
  old_password: Yup.string().required("Password required."),
  new_password: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      "Password must meet the criteria of 8-16 alphanumeric character with min 1 special character."
    )
    .required("New password is required."),
  confirm_password: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match."),
});

export const varificationSchema = Yup.object({
  method: Yup.string().required("Please select method"),
});

export const OtpSchema = Yup.object({
  otp: Yup.number().required("Required field!"),
});

export const varifyEmailOtpSchema = Yup.object({
  emailOtp: Yup.number().required("Required field!"),
  //whatsappOtp: Yup.number().required("Required field!"),
});
//Add entity schema
export const activeModalSchema = Yup.object({
  legalName: Yup.string().required("Legal name is Required."),
  // jurisdiction: Yup.string().required("Jurisdiction is required."),
  // organizationForm: Yup.string().required("Organization is required."),
  // registeredAgent: Yup.string().required("Agent is required."),
  // jurisdiction: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Jurisdiction is Required"),
  // }),

  // organizationForm: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Organization is Required"),
  // }),
  // registeredAgent: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required"),
  // }),
  legalAdd1: Yup.string().required("Legal address is Required."),
  legalAdd2: Yup.string().required("Legal address is Required."),
  legalCity: Yup.string().required("Legal city is Required."),
  legalCountry: Yup.string().required("Legal country is Required."),
  legalRegion: Yup.string().required("Legal region is Required."),
  legalPostalCode: Yup.string().required("Legal postal code is Required."),

  incorporationDate: Yup.string().required(
    "Incorporation date is required is Required."
  ),
  jurisdiction: Yup.string().required("Jurisdiction is Required"),
  organizationForm: Yup.string().required("Organization is Required"),
  registeredAgent: Yup.string().required("Required!"),
});

export const editEntitySchema = Yup.object({
  legalName: Yup.string().required("Legal name is required."),
  jurisdiction: Yup.string().required("Jurisdiction is required!"),
  organizationForm: Yup.string().required("Organization is required!"),
  legalAdd1: Yup.string().required("Legal address is required."),
  legalAdd2: Yup.string().required("Legal address is required."),
  legalCity: Yup.string().required("Legal city is required."),
  legalCountry: Yup.string().required("Legal country is required."),
  legalRegion: Yup.string().required("Legal region is required."),
  legalPostalCode: Yup.string().required("Legal postal code is required."),

  incorporationDate: Yup.string().required(
    "Incorporation date is required is required."
  ),
  registeredAgent: Yup.string().required("Agent is required!"),
});

export const addOwnerShipModalSchema = Yup.object({
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Type is required"),
  }),

  entity: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Entity is required"),
  }),
});
export const usersModalSchema = Yup.object({
  firstname: Yup.string().trim().required("Required!"),
  lastname: Yup.string().trim().required("Required!"),
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  groups: Yup.array().min(1, "Select at least one option"),
  roles: Yup.array().min(1, "Select at least one option"),
  phone: Yup.string().required("Required!"),
});
export const groupModalSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
});
export const recipientModalSchema = Yup.object({
  name: Yup.string().required("Required!"),
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
});

export const reportModalSchema = Yup.object({
  legalName: Yup.string().trim().required("Required!"),
});

export const documentTypeSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
});
export const organisationSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
});
export const tagsSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
});
export const folderSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
});
export const customFieldsSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
  order: Yup.number().required("Required!"),
});
export const jurisdictionsSchema = Yup.object({
  title: Yup.string().trim().required("Required!"),
  fee: Yup.string().required("Required!"),

  jurisdiction: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Required"),
  }),
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Required"),
  }),
});
export const serviceTypeSchema = Yup.object({
  title: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Title must contain only letters and spaces")
    .required("Required!"),
  fee: Yup.number().required("Required!"),
  jurisdiction: Yup.string().required("Required!"),
  type: Yup.string().required("Required!"),
  renewalFee: Yup.number().when("type", {
    is: (value) => value === "recurring",
    then: Yup.number().required("Required!"),
  }),
});

export const taskModalSchema = Yup.object({
  title: Yup.string().required("Required!"),
  dueDate: Yup.string().required("Required!"),
  assigned: Yup.array()
    .min(1, "Select at least1 one item")
});
export const addQuestionSchema = Yup.object({
  title: Yup.string().required("Required!"),
});
export const editQuestionSchema = Yup.object({
  title: Yup.string().required("Required!"),
});
//Add document schema
//Add entity schema

export const editDocumentSchema = Yup.object({
  title: Yup.string().required("Title is required."),
  jurisdiction: Yup.string().required("Jurisdiction is required!"),
  folder: Yup.string().required("Folder is required!"),
  documentType: Yup.string().required("Document Type is required!"),
  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
});

export const addDocumentSchema = Yup.object({
  title: Yup.string().required("Title is required."),
  jurisdiction: Yup.string().required("Required!"),

  folder: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Folder is required"),
  }),
  documentType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Type is required"),
  }),

  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
});
//add jurisdiction validation
export const addJurisdictionSchema = Yup.object({
  country: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Country is required"),
  }),
  state: Yup.string().required("Required."),
});
//add requirement validation
export const addRequirements = Yup.object({
  summary: Yup.string().required("Summary is required."),
  jurisdiction: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Jurisdiction is required"),
  }),
  requirementType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Requirement type is required"),
  }),
  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
});

export const editRequirement = Yup.object({
  summary: Yup.string().required("Summary is required."),
  jurisdiction: Yup.string().required("Jurisdiction is required."),
  requirementType: Yup.string().required("Requirement type is required."),
  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
});
//add peoples validation
export const addPeoples = Yup.object({
  contact: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Required!"),
  }),
  title: Yup.string().required("Required!"),
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Required!"),
  }),
  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
  // appointmentDate:Yup.string().required("Required!")
});

export const editPeople = Yup.object({
  contact: Yup.string().required("Required!"),
  title: Yup.string().required("Required!"),
  type: Yup.string().required("Required!"),
  sendAlert: Yup.boolean(),
  recipients: Yup.array().when("sendAlert", {
    is: true,
    then: Yup.array().min(1, "Select at least one recipient"),
    otherwise: Yup.array(),
  }),
});
//add requirement validation
export const addNotes = Yup.object({
  content: Yup.string().required("Required!"),
});

export const informationSheetValidationSchema = Yup.object().shape({
  // informationType: Yup.string().required("Information Type is required"),
  companyName: Yup.string().required("Company Name is required"),
  stateOfIncorporation: Yup.string().required(
    "State of Incorporation is required"
  ),
  einTaxNumber: Yup.string().required("EIN / Tax Number is required"),
  natureOfBusiness: Yup.string().required("Nature of Business is required"),
  residentialAddress: Yup.string().required("Residential Address is required"),
  physicalAddress: Yup.string().required("Physical Address is required"),
  mailingAddress: Yup.string().required("Mailing Address is required"),
  // bankSigner: Yup.string().required("Bank Signer is required"),
  // additionalSigner: Yup.string().required("Additional Signer is required"),
  primaryMobile: Yup.string().required("Primary Mobile is required"),
  secondaryMobile: Yup.string(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  bankName: Yup.string().required("Bank Name is required"),
  cardTitle: Yup.string().required("Card Title is required"),
  cardNumber: Yup.string().required("Card Number is required"),
  expiryDate: Yup.string().required("Expiry Date is required"),
  cvsCvv: Yup.string()
    .min(3, "CVS / CVV must be at least 3 characters")
    .max(3)
    .required("CVS / CVV is required"),
  // pin: Yup.string().required("PIN is required"),
  // tokenLink: Yup.string().required("Token Link is required"),
  // tokenMobile: Yup.string().required("Token Mobile is required"),
  // tokenEmail: Yup.string().email("Invalid email address").required("Token Email is required"),
  // tokenPassword: Yup.string().required("Token Password is required"),
  // vpsName: Yup.string().required("VPS Name is required"),
  // externalIP: Yup.string().required("External IP is required"),
  // internalIP: Yup.string().required("Internal IP is required"),
  // vpsUsername: Yup.string().required("VPS Username is required"),
  // vpsPassword: Yup.string().required("VPS Password is required"),
  // logo: Yup.string().required("Logo is required"),
});

//add requirement validation
export const addContact = Yup.object({
  fullName: Yup.string().required("Required!"),
});

export const orderModalSchema = Yup.object({
  accountName: Yup.string().required("Required!"),
  name: Yup.string().required("Required!"),
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  billingAddress: Yup.string().required("Required!"),
  billingCity: Yup.string().required("Required!"),
  billingRegion: Yup.string().required("Required!"),
  billingCountry: Yup.string().required("Required!"),
  billingPostalCode: Yup.string().required("Required!"),
  //jurisdiction: Yup.string().required("Required!"),
  currency: Yup.string().required("Required!"),
});

export const currencyModalSchema = Yup.object({
  title: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("Required"),
  }),
});

export const deliveryPartnerModalSchema = Yup.object({
  title: Yup.string().required("Required Field !"),
});

export const reportSchema = Yup.object({
  title: Yup.string().required("Required!"),
});
export const designationSchema = Yup.object({
  title: Yup.string().required("Required!"),
});

export const orderCompanySchema = Yup.object({
  // jurisdiction: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required!"),
  // }),

  // organizationForm: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required!"),
  // }),
  // deliveryPartner: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required!"),
  // }),
  // currency: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required!"),
  // }),
  // service: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required"),
  // }),
  // additionalService: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required("Required"),
  // }),
  jurisdiction: Yup.string().required("Required!"),
  organizationForm: Yup.string().required("Required!"),
  deliveryPartner: Yup.string().required("Required!"),
  currency: Yup.string().required("Required!"),
  service: Yup.string().required("Required!"),
  //additionalService: Yup.string().required("Required!"),
  accountName: Yup.string().required("Required!"),
  billingAddress: Yup.string().required("Required!"),
  billingAddress2: Yup.string().required("Required!"),
  billingCity: Yup.string().required("Required!"),
  billingCountry: Yup.string().required("Required!"),
  billingRegion: Yup.string().required("Required!"),
  billingPostalCode: Yup.string().required("Required!"),
  firstname: Yup.string().required("Required!"),
  email: Yup.string().required("Required!"),
  phone: Yup.string()
    .required("Required!")
    .max(15, "Phone must be at most 10 characters"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

export const editContact = Yup.object({
  fullName: Yup.string().required("Required!"),
});
