import { createSlice } from "@reduxjs/toolkit";
import { addOrderThunk, addPlanThunk, getOrdersThunk, getPlansThunk } from "./orderThunk";
import { handleOopsError, handleSuccess } from "../../../../utils/toastMessage";

const initialState = {
  order: {},
  orders: [],
  plan: {},
  plans: [],
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
  planTotal:0
}

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrderThunk.fulfilled, (state, action) => {
        if (action?.payload != undefined && (action?.payload?.status === 200 || action?.payload?.status === 201)) {
          const { doc } = action.payload.data.data
          //state.orders = { ...state.orders, doc }
          handleSuccess('Order placed successfully')
        }
      })

      .addCase(getOrdersThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.orders = action.payload.data.data;
          state.total = action?.payload?.data?.total;
          state.loading = false;
        }
      })
      .addCase(addPlanThunk.fulfilled, (state, action) => {
        if (action?.payload != undefined && (action?.payload?.status === 200 || action?.payload?.status === 201)) {
          const { doc } = action.payload.data.data
          //state.orders = { ...state.orders, doc }
          handleSuccess('Plan created successfully')
        }
      })

      .addCase(getPlansThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.plans = action.payload.data.data;
          state.planTotal = action?.payload?.data?.planTotal;
          state.loading = false;
        }
      })

  }
})


export const { changeLimit, changePage } = orderSlice.actions
export default orderSlice.reducer;
