import { toast } from 'react-toastify';

const handleSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
    });
  };
  
  const handleError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
    });
  };
  const handleWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
    });
  };
  const handleOopsError = () => {
    toast.error('Oops! Something went wrong!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
    });
  };
 
  export {handleSuccess , handleError ,handleOopsError,handleWarning }