import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../features/auth/authSelector";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export default function ProtectedRoutes({ Component }) {
  const navigate = useNavigate();
  const { token } = useSelector(authSelector);
  // let token = localStorage.getItem('token')
  useEffect(() => {
    if (!token || token === "undefined" || token == null) {
      navigate("/signin");
    } else {
      const currentDate = new Date();
      const next30Days = new Date(
        currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
      );
      const next30DaysUnixTime = Math.floor(next30Days / 1000);
      const decodedToken = jwtDecode(token);
   
      if (decodedToken.iat  > next30DaysUnixTime) {
        navigate("/signin");
        localStorage.clear();
        toast.error("Token expired");
        sessionStorage.clear();
      }
    }
  }, []);

  return (
    <>
      <Component />
    </>
  );
}
