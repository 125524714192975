import { createSlice } from "@reduxjs/toolkit";
import { addDocumentTypeThunk, deleteDocumentTypeThunk, getDocumentTypeThunk } from "./documentTypeThunk";
import { handleOopsError, handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  document: {},
  documents: [],
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
  documentOptions:[],
}

const documentTypeSlice = createSlice({
  name: "documentType",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
     state.limit = action.payload;
    },
    changePage: (state, action) => {
     state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDocumentTypeThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data
        state.documents = { ...state.documents, doc }
        handleSuccess('Document type saved successfuly')
      })
      .addCase(addDocumentTypeThunk.pending, (state, action) => {
      })
      .addCase(addDocumentTypeThunk.rejected, (state, action) => {
       })

      //  get document
      .addCase(getDocumentTypeThunk.fulfilled, (state, action) => {
       
        const { data } = action.payload.data.data
      
        state.total = action?.payload?.data?.total
        state.result = action?.payload?.data?.results

        let tempOptions=[];
        if(data.length > 0){
          for(let item of data){
            tempOptions.push({value:item._id , label:item.title });
          }
          state.documents = {...state.documents,data}
          state.documentOptions=[...tempOptions]
        }
        
      })
      .addCase(getDocumentTypeThunk.pending, (state, action) => {
     

      })

      .addCase(getDocumentTypeThunk.rejected, (state, action) => {
        // console.log(action)
      })





      .addCase(deleteDocumentTypeThunk.fulfilled, (state, action) => {
        // console.log("delete api", action.payload);
        // state.groups.filter((item) => item.id !== action.payload._id)
      })


  }
})


export const { changeLimit, changePage } = documentTypeSlice.actions
export default documentTypeSlice.reducer;