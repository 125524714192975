import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setEntityUser, setToken } from "../../utils/helpers";
const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`

//login thunk
export const loginThunk = createAsyncThunk("auth/login", async (body) => {
  try {
    const data = await axios.post(`${api_url}/login`, body);
    
    
    if (
      data?.data?.user?.isAuthenticatorVerified === 1 &&
      data?.data?.user?.isEmailVerified === 1
    ) {
      localStorage.setItem("tempToken", JSON.stringify(data.data.data.token));
      localStorage.setItem("isRemember", JSON.stringify(body.rememberMe));
    }
    // console.log(data.data.data.user)
    return data;
  } catch (error) {
    return error;
  }
});
// sign up thunk
export const registerThunk = createAsyncThunk("auth/register", async (body) => {
  try {
    const data = await axios.post(`${api_url}/signup`, body);
    if (
      data?.data?.user?.isAuthenticatorVerified === 1 &&
      data?.data?.user?.isEmailVerified === 1
    ) {
      localStorage.setItem("tempToken", JSON.stringify(data.data.data.token));
    }

    return data;
  } catch (error) {
    return error;
  }
});
// forgot password
export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/forgotPassword`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// reset password
export const resetPasswordThunk = createAsyncThunk(
  "auth/resetPassword",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/resetPassword`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// verify email otp
export const verifyEmailOtpThunk = createAsyncThunk(
  "auth/verifyEmailOtp",
  async (body) => {
    // console.log({body});
    try {
      const data = await axios.post(`${api_url}/verifyEmailOtp`, body);
      console.log("auth/verifyEmailOtp",data);
      localStorage.setItem("tempToken", JSON.stringify(data?.data?.data?.token));
      return data;
    } catch (error) {
      return error;
    }
  }
);
// send whatsAppOtpThunk
export const whatsAppOtpThunk = createAsyncThunk(
  "auth/whatsapp",
  async (body) => {
    // console.log({body});
    try {
      const data = await axios.post(`${api_url}/whatsapp`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//verify whatsAppOtpThunk
export const verifyWhatsAppOtpThunk = createAsyncThunk(
  "auth/verifyWhatsappOtp",
  async (body) => {
    // console.log({body});
    try {
      const data = await axios.post(`${api_url}/verifyWhatsappOtp`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//resend otp
export const resendEmailOtpThunk = createAsyncThunk(
  "auth/sendEmailOtp",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/sendEmailOtp`, body);
      return data;
    } catch (error) {
      return;
    }
  }
);
//send phone otp
export const sendPhoneOtpThunk = createAsyncThunk(
  "auth/sendPhoneOtp",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/sendPhoneOtp`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//verify phone otp
export const verifyPhoneOtpThunk = createAsyncThunk(
  "auth/verifyPhoneOtp",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/verifyPhoneOtp`, body);

      return data;
    } catch (error) {
      return error;
    }
  }
);
//update profile
export const updateProfileThunk = createAsyncThunk(
  "auth/updateProfile",
  async ({ body, headers }) => {
    // console.log({body,headers});
    const config = {
      headers: {
        Authorization: headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const data = await axios.patch(`${api_url}/updateProfile`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//change password
export const changePasswordThunk = createAsyncThunk(
  "auth/updatePass",
  async ({ body, headers }) => {
    // console.log({body,headers});
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(
        `${api_url}/users/changePassword`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const resendAppOtpThunk = createAsyncThunk(
  "auth/sendAuthenticatorQrCode",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/sendAuthenticatorQrCode`, body);
      return data;
    } catch (error) {
      return;
    }
  }
);

export const verifyAppOtpThunk = createAsyncThunk(
  "auth/verifyAuthenticatorQrCode",
  async (body) => {
    try {
      const data = await axios.post(
        `${api_url}/verifyAuthenticatorQrCode`,
        body
      );
      console.log("auth/verifyAuthenticatorQrCode",data);

      localStorage.setItem("tempToken", JSON.stringify(data?.data?.data?.token));
      

      return data;
    } catch (error) {
      return;
    }
  }
);

export const verifyRegenerateOtpThunk = createAsyncThunk(
  "auth/verifyRegenerateOTP",
  async (body) => {
    try {
      const data = await axios.post(
        `${api_url}/verifyRegenerateQrCodeOtp`,
        body
      );    
      return data;
    } catch (error) {
      return;
    }
  }
);
