import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`


//  group add thunk
export const addGroupThunk = createAsyncThunk(
  'group/add',
  async ({ body, headers }) => {
  
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/groups`, body, config)
     
      return data
    } catch (error) {

      return error
    }

  }

)

// get group thunk
export const getGroupThunk = createAsyncThunk(
  'group/get',
  async ({ headers, params }) => {
 
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/groups`, config)
    
      return data

    } catch (error) {
      // return error

    }
  }
)
// delete group thunk
export const deleteGroupThunk = createAsyncThunk(
  'gruop/delete',
  async ({ headers,_id }) => {
  
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/groups/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }


  }
)

// update group thunk
export const updateGroupThunk = createAsyncThunk(
  'gruop/update',
  async ({ headers }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.patch(`${api_url}/groups`, config)
      return data

    } catch (error) {
      return error
    }


  }
)

