import { createSlice } from "@reduxjs/toolkit";

import {
  changePasswordThunk,
  forgotPasswordThunk,
  loginThunk,
  registerThunk,
  resendEmailOtpThunk,
  resetPasswordThunk,
  updateProfileThunk,
  verifyEmailOtpThunk,
  verifyWhatsAppOtpThunk,
  whatsAppOtpThunk,
  resendAppOtpThunk,
  verifyAppOtpThunk,
  sendPhoneOtpThunk,
  verifyPhoneOtpThunk,
  verifyRegenerateOtpThunk,
} from "./authThunk";
import { toast } from "react-toastify";
import { handleError, handleSuccess } from "../../utils/toastMessage";
import { setEntityUser } from "../../utils/helpers";

const initialState = {
  // login
  isUserVerified: localStorage.getItem("isVerified") ? true : false,
  isLoggedIn: false,
  isLoggingStatus: "",
  isLoading: false,
  isLoggingError: false,
  loggingErrorMsg: "",
  userData: JSON.parse(localStorage.getItem("user")),
  token:
    JSON.parse(sessionStorage.getItem("userToken")) ??
    JSON.parse(localStorage.getItem("tempToken")),
  // register
  isRegisterSuccess: false,
  isRegisterStatus: "idle",
  isRegisterError: false,
  registerErrorMsg: "",
  // forgot password
  forgotPasswordStatus: "idle",
  isForgotPasswordError: false,
  forgotPasswordErrorMsg: "",
  isForgotPasswordSuccess: false,
  // verify
  otpVerified: false,
  responseOtp: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshStatus: (state) => {
      state.isLoggedIn = false;
      state.isLoggingStatus = "";
      state.isLoggingError = false;
      state.isRegisterSuccess = false;
      state.userData = null;
      state.loggingErrorMsg = "";
      state.isRegisterStatus = false;
      state.isRegisterError = false;
      state.registerErrorMsg = "";
    },
    addToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // login thunk
      .addCase(loginThunk.pending, (state) => {
        state.isLoggingStatus = "loading";
        state.isLoggingError = false;
        state.isLoggedIn = false;
        state.isLoading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        // console.log("actions", action);
        if (action.payload.status !== 200) {
          state.isLoggingStatus = "failed";
          state.loggingErrorMsg = action.payload.data;
          state.isLoading = false;
          let errorMessage = action?.payload?.response.data?.message;
          handleError(errorMessage);
        } else {
          state.isLoggedIn = true;
          setEntityUser(action?.payload?.data?.data?.user);
          state.userData = { ...action?.payload?.data?.data?.user };
          state.isLoading = false;
          state.loggingErrorMsg = action.payload.data.status;

          const isRemember = JSON.parse(localStorage.getItem("isRemember"));
          if (isRemember) {
            //Saving in permanent storage for future ref
          }
        }
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loggingErrorMsg = action?.error?.msg ? action.error.msg : "";
        state.isLoggingStatus = "failed";
        state.isLoggingError = true;
        state.isLoggedIn = false;
        state.isLoading = false;
      })

      // register thunk

      .addCase(registerThunk.pending, (state, action) => {
        state.isRegisterStatus = "loading";
        state.isRegisterError = false;
        state.isRegisterSuccess = false;
      })
      .addCase(registerThunk.fulfilled, (state, action) => {
        console.log("payload", action.payload);
        if (action?.payload?.response?.status === 422) {
          state.isRegisterStatus = "idle";
          state.isRegisterError = false;
          

          // state.isRegisterSuccess = action.payload.response.data.status
          // state.registerErrorMsg = action.payload.response.data.msg
        } else if (action?.payload?.status === 201) {
          state.isRegisterStatus = "idle";
          state.isRegisterError = false;
          state.isRegisterSuccess = action.payload.data.status;
          state.registerErrorMsg = action.payload.data.msg;
          setEntityUser(action?.payload?.data?.data?.user);
          state.userData = { ...action?.payload?.data?.data?.user };
        } else {
          
        }
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.isRegisterError = true;
        state.registerErrorMsg = action.payload.error.response.data.msg;
        state.isRegisterSuccess = false;

        state.isRegisterStatus = "failed";
      })

      // forgot password
      .addCase(forgotPasswordThunk.pending, (state, action) => {
        state.forgotPasswordStatus = "loading";
        state.isForgotPasswordError = false;
        state.isForgotPasswordSuccess = false;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.forgotPasswordStatus = "idle";
        state.isForgotPasswordError = false;
        state.forgotPasswordErrorMsg = "";
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.forgotPasswordStatus = "failed";
        state.isForgotPasswordError = false;
        state.forgotPasswordErrorMsg = "";
      })
      //  reset password
      .addCase(resetPasswordThunk.pending, (state, action) => {})
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          toast.success("Password reset successfully");
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {})

      // verifyEmailOtp
      .addCase(verifyEmailOtpThunk.fulfilled, (state, action) => {
        if (action?.payload?.response) {
          if (action.payload.response.status === 404) {
            toast.error(action.payload.response.data.message);
            state.otpVerified = false;
          }
        } else if (action?.payload?.status === 200) {
          // toast.success("OTP verified")
          state.otpVerified = true;
        }
      })
      // verifyPhoneOtpThunk
      .addCase(verifyPhoneOtpThunk.fulfilled, (state, action) => {

       
        if (action?.payload?.response) {
          if (action.payload.response.status === 404) {
            toast.error(action.payload.response.data.message);
            state.otpVerified = false;
          }
        } else if (action?.payload?.status === 200) {
          // toast.success("OTP verified")
          console.log("actionpayload", action.payload);
          setEntityUser(action?.payload?.data?.data?.user);

          state.userData = action?.payload?.data?.data?.user;
          state.otpVerified = true;
        }
      })
      .addCase(resendEmailOtpThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        if (
          action?.payload?.status === 201 ||
          action?.payload?.status === 200
        ) {
          toast.success("Email OTP sent");
        }
      })
      .addCase(sendPhoneOtpThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        if (
          action?.payload?.status === 201 ||
          action?.payload?.status === 200
        ) {
          toast.success("OTP sent");
        }
      })
      .addCase(updateProfileThunk.fulfilled, (state, action) => {
        console.log(action.payload, "payloadd");
        // console.log(action.payload);
        if (action.payload.status === 201) {
          const { updatedUser } = action.payload.data.data;
          console.log(updatedUser, "userr");
          const isRemember = JSON.parse(localStorage.getItem("isRemember"));
          if (isRemember) {
            //Saving in permanent storage for future ref\
            setEntityUser(updatedUser);
            state.userData = updatedUser;
            console.log(state.userData, "userDA");
          } else {
            //Just saving in temp storage
            setEntityUser(updatedUser);

            state.userData = updatedUser;
            state.isLoading = false;
            state.loggingErrorMsg = action.payload.data.status;
          }
          // handleSuccess("Profile Updated");
        } else {
          // handleError(action.payload.response.data.message);
          console.log(action.payload, "payloaddd");
        }
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          handleSuccess("Password Updated");
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })
      .addCase(whatsAppOtpThunk.fulfilled, (state, action) => {
        if (
          action?.payload?.status === 201 ||
          action?.payload?.status === 200
        ) {
          handleSuccess("OTP sent on your whatsapp number.");
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })
      .addCase(verifyWhatsAppOtpThunk.fulfilled, (state, action) => {
        // if (action.payload.status == 200) {
        //   toast.success("OTP Verified.")
        // } else {
        //   toast.error(action.payload.response.data.message)
        // }
      })
      .addCase(resendAppOtpThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        if (
          action?.payload?.status === 201 ||
          action?.payload?.status === 200
        ) {
          toast.success("Scan code generated.");
        }
      })
      .addCase(verifyRegenerateOtpThunk.fulfilled, (state, action) => {
        state.userData.isAuthenticatorEnabled = false;
        state.userData.isAuthenticatorVerified = 0;

        localStorage.setItem("user", JSON.stringify(state.userData));
        ///localStorage.setItem('user', state.userData)
      });
  },
});

export const { refreshStatus, addToken, removeToken } = authSlice.actions;
export default authSlice.reducer;
