import React from "react";
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../features/auth/authSelector";
import ProtectedRoutes from "../components/auth/ProtectedRoutes";
import PageNotFound from "../common/PageNotFound";
import Loader from "../common/loader";
import ViewRequirement from "../components/admin/Reports/ViewRequirment";
import ViewAnnualReport from "../components/admin/Reports/ViewAnnualReport";


const Account = lazy(() => import("../components/admin/Account/Account"));
const Entity = lazy(() => import("../components/admin/Entity/Entity"));
const Forgotpassword = lazy(() => import("../components/auth/Forgotpassword"));
const Newpassword = lazy(() => import("../components/auth/Newpassword"));
const Otp = lazy(() => import("../components/auth/Otp"));
const Signin = lazy(() => import("../components/auth/Signin"));
const Signup = lazy(() => import("../components/auth/Signup"));
const Mainbilling = lazy(() =>
  import("../components/admin/Billing/Mainbilling")
);
const Main = lazy(() => import("../components/admin/Entitydetails/Main"));
const Jurisdiction = lazy(() =>
  import("../components/admin/Jurisdication/jurisdiction")
);
const Orderdetails = lazy(() =>
  import("../components/admin/orders/Orderdetails")
);
const Orderslist = lazy(() => import("../components/admin/orders/Orderslist"));
const Mainparent = lazy(() =>
  import("../components/admin/Parentsubsidiaries/Mainparent")
);
const Mainreports = lazy(() =>
  import("../components/admin/Reports/Mainreports")
);
const Mainservice = lazy(() =>
  import("../components/admin/service/Mainservice")
);
const Customers = lazy(() => import("../components/admin/setting/customers/Customers"));
const Allexport = lazy(() =>
  import("../components/admin/setting/Export/Allexport")
);
const Allfolders = lazy(() =>
  import("../components/admin/setting/folders/Allfolders")
);
const Subscription = lazy(() =>
  import("../components/admin/setting/Subscription/Main")
);
const Alltrash = lazy(() =>
  import("../components/admin/setting/Trash/Alltrash")
);
const Allusers = lazy(() =>
  import("../components/admin/setting/users/Allusers")
);
const Tasks = lazy(() => import("../components/admin/Tasks"));
const ViewTasks = lazy(() => import("../components/admin/View/viewTasks"));
const TaskView = lazy(() =>
  import("../components/admin/TaskDetails/taskDetails")
);
const QuestionList = lazy(() =>
  import("../components/admin/QuestionsListing/QuestionList")
);
const Dashboard = lazy(() => import("../components/admin/Dashboard/dashboard"));


const Allpeople = lazy(() =>
  import("../components/admin/Peopleorganisation/Allpeople")
);

const AddSubscription = lazy(() =>
  import("../components/admin/setting/Subscription/AddSubscription")
);
const Checkout = lazy(() =>
  import("../components/admin/setting/Subscription/Checkout")
);
const Verification = lazy(() => import("../components/auth/Verification"));

const Allcurrencies = lazy(() =>
  import("../components/admin/setting/currency/Allcurrencies")
);

const Documents = lazy(() => import("../components/admin/View/Documents"));
const ViewDocument = lazy(() => import("../components/admin/Reports/ViewDocument"));

const Alerts = lazy(() => import("../components/admin/View/Alerts"));
const MainRequirements = lazy(() =>
  import("../components/admin/View/MainRequirements")
);
const QuestionMcq = lazy(() =>
  import("../components/admin/setting/users/QuestionMcq")
);

const CreateOrder = lazy(() =>
  import("../components/admin/orders/CreateOrder")
);

const Routing = () => {

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgotpassword" element={<Forgotpassword />} />
        <Route exact path="/verify-otp" element={<Otp />} />
        <Route
          exact
          path="/password-reset/:userId/:usertoken"
          element={<Newpassword />}
        />
        <Route exact path="/verification" element={<Verification />} />
        <Route exact path="/question" element={<QuestionMcq />} />

        <Route path="/" element={<ProtectedRoutes Component={Account} />} />
        <Route
          path="/account"
          element={<ProtectedRoutes Component={Account} />}
        />
        <Route
          path="/entitymanagement"
          element={<ProtectedRoutes Component={Entity} />}
        />
        <Route
          exact
          path="/entity"
          element={<ProtectedRoutes Component={Main} />}
        />
        <Route
          exact
          path="/customers"
          element={<ProtectedRoutes Component={Customers} />}
        />
        <Route
          exact
          path="/usersgroups"
          element={<ProtectedRoutes Component={Allusers} />}
        />
        <Route
          exact
          path="/lookupsfoldersfileds"
          element={<ProtectedRoutes Component={Allfolders} />}
        />
        <Route
          exact
          path="/export"
          element={<ProtectedRoutes Component={Allexport} />}
        />
        <Route
          exact
          path="/trash"
          element={<ProtectedRoutes Component={Alltrash} />}
        />
        <Route
          exact
          path="/subscription"
          element={<ProtectedRoutes Component={Subscription} />}
        />
        <Route
          exact
          path="/subscription/buy"
          element={<ProtectedRoutes Component={AddSubscription} />}
        />
        <Route
          exact
          path="/subscription/checkout"
          element={<ProtectedRoutes Component={Checkout} />}
        />
        <Route
          exact
          path="/reports"
          element={<ProtectedRoutes Component={Mainreports} />}
        />
        <Route
          exact
          path="/tasks"
          element={<ProtectedRoutes Component={Tasks} />}
        />
        <Route
          exact
          path="/jurisdiction"
          element={<ProtectedRoutes Component={Jurisdiction} />}
        />
        <Route
          exact
          path="/parentssubsidiaries"
          element={<ProtectedRoutes Component={Mainparent} />}
        />

        <Route
          exact
          path="/people-organizations"
          element={<ProtectedRoutes Component={Allpeople} />}
        />

        <Route
          exact
          path="/services"
          element={<ProtectedRoutes Component={Mainservice} />}
        />

        <Route
          exact
          path="/orders"
          element={<ProtectedRoutes Component={Orderslist} />}
        />
        <Route
          exact
          path="/orderdetails"
          element={<ProtectedRoutes Component={Orderdetails} />}
        />
        <Route
          exact
          path="/billing"
          element={<ProtectedRoutes Component={Mainbilling} />}
        />
        <Route
          exact
          path="/viewtask/:id"
          element={<ProtectedRoutes Component={ViewTasks} />}
        />
        <Route
          exact
          path="/edittask/:id"
          element={<ProtectedRoutes Component={TaskView} />}
        />
        <Route
          exact
          path="/questionlisting"
          element={<ProtectedRoutes Component={QuestionList} />}
        />
        <Route
          exact
          path="/dashboard"
          element={<ProtectedRoutes Component={Dashboard} />}
        />

        <Route
          exact
          path="/currencies"
          element={<ProtectedRoutes Component={Allcurrencies} />}
        />
        <Route
          exact
          path="/documents"
          element={<ProtectedRoutes Component={Documents} />}
        />
        <Route
          exact
          path="/viewdocument"
          element={<ProtectedRoutes Component={ViewDocument} />}
        />
        <Route
          exact
          path="/alerts"
          element={<ProtectedRoutes Component={Alerts} />}
        />
        <Route
          exact
          path="/requirements"
          element={<ProtectedRoutes Component={MainRequirements} />}
        />
        <Route
          exact
          path="/viewrequirement"
          element={<ProtectedRoutes Component={ViewRequirement} />}
        />
        <Route
          exact
          path="/viewannualreport"
          element={<ProtectedRoutes Component={ViewAnnualReport} />}
        />
        <Route
          exact
          path="/createorder"
          element={<ProtectedRoutes Component={CreateOrder} />}
        />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};
export default Routing;
