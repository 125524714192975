import React from "react";
import Sidebartoggle from "../../../common/Sidebartoggle";
import Requirement from "../setting/folders/lockuptabs/Requirement";
import { useLocation } from "react-router-dom";
import { dateFormatter } from "../../../utils/dateFormatHelper";

function ViewRequirement() {
  const location = useLocation();
 
  return (
    <>
      <div className="common_section">
        <div
          className="d-flex justify-content-between align-items-center common_section position-relative"
          id="header"
        >
          <h2 className="mb-0">
            <Sidebartoggle />
            Requirement Details
          </h2>
        </div>

        <section className="entity">
          <div className="entity_components">
            <div className="white_shadow_box pb-0">
              <div className="table_head px-0">
                <h3 className="mb-4">Document Title </h3>
              </div>
              <div className="order_details mt-3">
                <div className="row">
                  <div className="col-md-6 padding_right">
                    <div className="details_flex">
                      <p>
                        Name <span>-</span>
                      </p>
                      <h6>{location?.state?.details} </h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Jurisdiction <span>-</span>
                      </p>
                      <h6>{`${location?.state?.jurisdiction?.state},${location?.state?.jurisdiction?.country}`}</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Type <span>-</span>
                      </p>
                      <h6>{location?.state?.requirementType?.title}</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Registration Name <span>-</span>
                      </p>
                      <h6>November 23, 2024</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Registered Date <span>-</span>
                      </p>
                      <h6>{dateFormatter(location?.state?.createdAt)}</h6>
                    </div>

                    <div className="details_flex">
                      <p>
                        Details <span>-</span>
                      </p>
                      <h6>{location?.state?.notes}</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Recurring <span>-</span>
                      </p>
                      <h6>{location?.state?.entity?.legalName}</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Due Date <span>-</span>
                      </p>
                      <h6>{dateFormatter(location?.state?.dueDate)}</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                      Legal Address <span>-</span>
                      </p>
                      <h6>
                        {`${location?.state?.entity?.headquaterAdd1},${location?.state?.entity?.headquaterAdd2},${location?.state?.entity?.headquaterAdd1},${location?.state?.entity?.headquaterAdd2}`}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ViewRequirement;
