import { createSlice } from "@reduxjs/toolkit";
import { addRecipientThunk, deleteRecipientThunk, getRecipientThunk } from "./recipientThunk";
import { handleOopsError, handleSuccess } from "../../../utils/toastMessage";

const initialState = {
  recipientsOptions: [],
  recipient: {},
  recipients: [],
  currentPage: 1,
  numberOfPages: null,
  error: "",
  loading: false,
  total: 0,
  limit: 10
}

const recipientSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.currentPage = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(addRecipientThunk.fulfilled, (state, action) => {
        const { doc } = action.payload.data.data
        state.recipients = { ...state.recipients, doc }
        handleSuccess('Recipient detail saved successfuly')
      })
      .addCase(addRecipientThunk.pending, (state, action) => {
        // console.log({ action });
        state.documentStatus = false
      })

      .addCase(addRecipientThunk.rejected, (state, action) => {
      })

      //  get document
      .addCase(getRecipientThunk.fulfilled, (state, action) => {
        // console.log("get documents slice", action.payload);
        const { data } = action.payload.data.data
        state.total = action?.payload?.data?.total;
        let tempRecipients = []
        if (data.length > 0) {
          for (let item of data) {
            tempRecipients.push({ value: item._id, label: item.name });
          }
          state.recipients = { ...state.recipients, data }
          state.recipientsOptions = [...tempRecipients]

        } else {
          state.recipients = [];
        }

      })
      .addCase(getRecipientThunk.pending, (state, action) => {
        // console.log(action)

      })

      .addCase(getRecipientThunk.rejected, (state, action) => {
        // console.log(action)
      })

      .addCase(deleteRecipientThunk.fulfilled, (state, action) => {
        // console.log("delete api", action.payload);
        // state.groups.filter((item) => item.id !== action.payload._id)
      })

  }
})

export const { changePage, changeLimit } = recipientSlice.actions
export default recipientSlice.reducer;