import { createSlice } from "@reduxjs/toolkit";
// import { addDocumentTypeThunk, deleteDocumentTypeThunk, getDocumentTypeThunk } from "./documentTypeThunk";
import { getRolesThunk } from "./rolesThunk";

const initialState = {
  role: {},
  roles: [],
  roleOptions:[],
  error: "",
  loading: false,
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
}

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    changeLimit: (state, action) => {

      state.limit = action.payload;
    },
    changePage: (state, action) => {

      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      //  get document
      .addCase(getRolesThunk.fulfilled, (state, action) => {
        const {data} = action.payload.data.data
        state.total = action?.payload?.data?.total;
        let tempOptions=[]

        if(data.length > 0){
          for(let item of data){
            if(item.slug != 'superadmin'){
              tempOptions.push({value:item.slug , label:item.title });
            }
          }
          state.roles = {...state.roles,data}
          state.roleOptions=[...tempOptions]

        }
          
      })
      
      .addCase(getRolesThunk.rejected, (state, action) => {
        // console.log(action)
      })

  }
})


export const { changeLimit, changePage } = roleSlice.actions
export default roleSlice.reducer;