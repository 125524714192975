import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../../utils/toastMessage";
const api_url = process.env.REACT_APP_API_URL_DEV ;

export const getTrashDocumentsThunk = createAsyncThunk(
  'trashDocument/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/documents`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashDocumentsThunk = createAsyncThunk(
  'trashDocument/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/documents`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashDocumentsThunk = createAsyncThunk(
  'trashDocument/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/documents`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashContactsThunk = createAsyncThunk(
  'trashContacts/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/contact`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashContactsThunk = createAsyncThunk(
  'trashContacts/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/contact`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashContactsThunk = createAsyncThunk(
  'trashContacts/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/contact`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);


export const getTrashRequirementsThunk = createAsyncThunk(
  'trashRequirement/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/requirements`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashRequirementsThunk = createAsyncThunk(
  'trashRequirement/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/requirements`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashRequirementsThunk = createAsyncThunk(
  'trashRequirement/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/requirements`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashReportsThunk = createAsyncThunk(
  'trashReport/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/requirements`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashReportsThunk = createAsyncThunk(
  'trashReport/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/requirements`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashReportsThunk = createAsyncThunk(
  'trashReport/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/requirements`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashTaskThunk = createAsyncThunk(
  'trashTask/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/tasks`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashTaskThunk = createAsyncThunk(
  'trashTask/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/tasks`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashTaskThunk = createAsyncThunk(
  'trashTask/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/tasks`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashNotesThunk = createAsyncThunk(
  'trashNotes/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/notes`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashNotesThunk = createAsyncThunk(
  'trashNotes/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/notes`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashNotesThunk = createAsyncThunk(
  'trashNotes/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/notes`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashPeoplesThunk = createAsyncThunk(
  'trashPeoples/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/peoples`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashPeoplesThunk = createAsyncThunk(
  'trashPeoples/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/peoples`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashPeoplesThunk = createAsyncThunk(
  'trashPeoples/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/peoples`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashOwnershipsThunk = createAsyncThunk(
  'trashOwnerships/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/ownership`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashOwnershipsThunk = createAsyncThunk(
  'trashOwnerships/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/ownership`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashOwnershipsThunk = createAsyncThunk(
  'trashOwnerships/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/ownership`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashAgentsThunk = createAsyncThunk(
  'trashAgents/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/register-agent`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)

export const restoreTrashAgentsThunk = createAsyncThunk(
  'trashAgents/update',
  async ({ headers, body, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    };
    try {
      const data = await axios.patch(`${api_url}/register-agent`, { itemIds, updates: body }, config);

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const removeTrashAgentsThunk = createAsyncThunk(
  'trashAgents/delete',
  async ({ headers, itemIds }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        'Content-Type': 'application/json'
      }
    };
    try {
      const data = await axios.delete(`${api_url}/register-agent`, { 
        data: { itemIds },
        ...config
      });

      return data;
    } catch (error) {
      handleError(error?.response?.data?.message);
    }
  }
);

export const getTrashUnrelatedEntitiesThunk = createAsyncThunk(
  'trashUnrelatedEntities/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/entities`, config)
      return data

    } catch (error) {
        handleError(error?.response?.data?.message);
    }
  }
)