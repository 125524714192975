import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`
//document  add thunk
export const addDocumentThunk = createAsyncThunk(
  'documents/add',
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "multipart/form-data",
      }
    }
    try {
      const data = await axios.post(`${api_url}/documents`, body, config)
      return data
    } catch (error) {
      return error
    }


  }

)

//document  update thunk
export const updateDocumentThunk = createAsyncThunk(
  'documents/update',
  async ({ body, headers, _id }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
     
    }
    try {
      const data = await axios.patch(`${api_url}/documents/${_id}`, body, config)
      return data
    } catch (error) {
      return error
    }


  }

)


// get documents thunk
export const getDocumentsThunk = createAsyncThunk(
  'documents/get',
  async ({ headers,params ,_id }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/entities/${_id}/documents`, config)
      return data

    } catch (error) {
      return error

    }
  }
)


// delete documents thunk
export const deleteDocumentThunk = createAsyncThunk(
  'documents/delete',
  async ({ headers,_id, params }) => {
    // console.log({headers,_id});
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }, 
      params:params
    }
    try {
      const data = await axios.delete(`${api_url}/documents/${_id}`,config)
      return data
    } catch (error) {
      return error
    }
  }
)


export const getTrashEntityThunk = createAsyncThunk(
  'trashDocument/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/documents`, config)
      return data

    } catch (error) {
      console.log(error,"error");
      return error

    }
  }
)

export const getUploadsThunk = createAsyncThunk(
  'document/uploads/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/documents`, config)
      return data

    } catch (error) {
      console.log(error,"error");
      return error

    }
  }
)

export const getLinksThunk = createAsyncThunk(
  'document/links/get',
  async ({ headers,params }) => {
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/documents`, config)
      return data

    } catch (error) {
      console.log(error,"error");
      return error

    }
  }
)

export const fetchDocumentFileThunk = createAsyncThunk(
  'documents/file/fetch',
  async ({ body, headers }) => {
    // console.log({headers,_id});
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/documents/download`, body, config)
      return data
    } catch (error) {
      return error
    }
  }
)
// share documents thunk
export const shareDocumentsThunk = createAsyncThunk(
  'documents/share',
  async ({ body, headers }) => {
    console.log({body});
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/documents/share`, body, config)
      return data
    } catch (error) {
      return error
    }
  }
)