import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

//  comment  add thunk
export const addTaskCommentThunk = createAsyncThunk(
  "tasksComment/add",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };

    try {
      const data = await axios.post(`${api_url}/taskcomment`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//  comment  add thunk
export const addTaskDocThunk = createAsyncThunk(
  "tasksDoc/upload",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const data = await axios.post(`${api_url}/taskdocument`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
// get task comments thunk
export const getTaskCommentThunk = createAsyncThunk(
  "tasksComment/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      if (_id) {
        const data = await axios.get(`${api_url}/taskcomment/${_id}`, config);
        return data;
      } else {
        const data = await axios.get(`${api_url}/taskcomment`, config);
        return data;
      }
    } catch (error) {
      return error;
    }
  }
);

// get task comments thunk
export const getTaskDocumentsThunk = createAsyncThunk(
  "tasksDoc/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/taskdocument/${_id}`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getTaskStatusThunk = createAsyncThunk(
  "taskStatus/get",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/taskstatus/${_id}`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const addTaskStatusThunk = createAsyncThunk(
  "tasksStatus/created",
  async ({ body, headers }) => {
    // console.log("config", body, headers);
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };

    try {
      const data = await axios.post(`${api_url}/taskstatus`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getCommentThunk = createAsyncThunk(
  "comment/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/taskcomment`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
