import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleOopsError, handleSuccess } from "../../utils/toastMessage";
import {
  addDocumentThunk,
  deleteDocumentThunk,
  getDocumentsThunk,
  updateDocumentThunk,
  getUploadsThunk,
  getLinksThunk,

} from "./documentsThunk";
const initialState = {
  documents: [],
  total: 0,
  isLoading: false,
  limit: 10,
  page: 1,
  sortOrder:1,
  result: 0,

  docLinks: [],
  docLinkPage: 1,
  docLinkLimit: 10,
  docLinkTotal: 0,

  docUploads: [],
  docUploadPage: 1,
  docUploadLimit: 10,
  docUploadTotal: 0,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    changeDocumentsLimit: (state, action) => {
      state.limit = action.payload;
    },
    changeDocumentsPage: (state, action) => {
      state.page = action.payload;
    },
    changeSortOrder: (state , payload)=>{
      if(state.sortOrder === 1){
        state.sortOrder = -1
      } else{
        state.sortOrder = 1
      }
    },
    changeDocLinkLimit: (state, action) => {
      state.docLinkLimit = action.payload;
    },
    changeDocLinkPage: (state, action) => {
      state.docLinkPage = action.payload;
    },
    changeDocUploadLimit: (state, action) => {
      state.docUploadLimit = action.payload;
    },
    changeDocUploadPage: (state, action) => {
      state.docUploadPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDocumentThunk.fulfilled, (state, action) => {
        if (action.payload.status === 201) {
          state.isLoading = false;
          handleSuccess("New document added successfully!");
        } else {
          state.isLoading = false;

          const { message } = action.payload?.response?.data;

          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      //On update success
      .addCase(updateDocumentThunk.fulfilled, (state, action) => {
        console.log(action.payload, "payload");
        if (action.payload.status === 201) {
          // const {entityRequirement} = action.payload?.data?.data;
          state.isLoading = false;
          handleSuccess("Document  Updated successfully!");
        } else {
          state.isLoading = false;
          const { message } = action.payload?.response?.data;
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .addCase(addDocumentThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addDocumentThunk.rejected, (state, action) => {
        state.isLoading = true;
        handleOopsError();
      })

      //  get document
      .addCase(getDocumentsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const { documents } = action?.payload?.data?.data;
        state.documents = [...documents];
        state.total = action?.payload?.data?.total;
      })
      .addCase(getDocumentsThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getDocumentsThunk.rejected, (state, action) => {
        // console.log(action)
        state.isLoading = false;
        // handleOopsError();
      })

      .addCase(deleteDocumentThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status === 201) {
          handleSuccess("Document removed successfully!");
        } else {
          handleOopsError();
        }
      })
      .addCase(deleteDocumentThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDocumentThunk.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getUploadsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.docUploads = action.payload.data.data;
          state.docUploadTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      })

      .addCase(getLinksThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.docLinks = action.payload.data.data;
          state.docLinkTotal = action?.payload?.data?.total;
          state.loading = false;
        }
      });
  },
});

export const { 
  changeDocumentsLimit, 
  changeDocumentsPage,
  changeSortOrder, 
  changeDocLinkLimit, 
  changeDocLinkPage,
  changeDocUploadLimit,
  changeDocUploadPage,

} = documentsSlice.actions;

export default documentsSlice.reducer;
