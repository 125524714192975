import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL_DEV;

//contact add thunk
export const addContactThunk = createAsyncThunk(
  "contact/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/contact`, body, config);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);
//delete contact thunk
export const deleteContactThunk = createAsyncThunk(
  "contact/delete",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.delete(`${api_url}/contact/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
// delete contact thunk
export const updateContactThunk = createAsyncThunk(
  "contact/update",
  async ({ headers, body, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/contact/${_id}`, body, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
// delete contact thunk
export const getContactThunk = createAsyncThunk(
  "contact/getAll",
  async ({ headers, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(
        `${api_url}/entities/${_id}/contact`,
        config
      );
      return { data };
    } catch (error) {
      return error;
    }
  }
);

export const getAllContactThunk = createAsyncThunk(
  "contacts/get",
  async ({ headers, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/contact`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
