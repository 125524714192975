import { createSlice } from "@reduxjs/toolkit"; 
import { addServiceThunk, deleterServiceThunk, getServiceThunk, getCategoryServicesThunk, editServiceThunk } from "./serviceThunk";
import { handleOopsError, handleSuccess } from "../../../../../utils/toastMessage";

const initialState = {
  // data: [],
  // jurisdiction: {},
  services: [],
  total: 0,
  limit: 10,
  page: 1,
  result: 0,
  customservices: [],
  customserviceOptions: [],
}

const servicesSlice = createSlice({
  name:"services",
  initialState,
  reducers:{
    changeLimit: (state, action) => {

      state.limit = action.payload;
    },
    changePage: (state, action) => {

      state.page = action.payload;
    }
  },
  extraReducers:(builder)=>{
    builder
    .addCase(addServiceThunk.fulfilled, (state, action) => {
      const { doc } = action.payload.data.data
      state.services={...state.services,doc}
      handleSuccess('Service detail saved successfully')
      // console.log("group slice",state.groups);
    })
    .addCase(addServiceThunk.pending, (state, action) => {
      // console.log({ action });
    
     
    })


    .addCase(addServiceThunk.rejected, (state, action) => {
     
 

    })
    .addCase(editServiceThunk.fulfilled, (state, action) => {
      // const { doc } = action.payload.data.data
      // state.services={...state.services,doc}
      handleSuccess('Service detail updated successfully')
      // console.log("group slice",state.groups);
    })
   //  get document
    .addCase(getServiceThunk.fulfilled, (state, action) => {
      state.services = action.payload.data?.data
      state.total = action?.payload?.data?.total
      state.result = action?.payload?.data?.results
    })
  .addCase(getServiceThunk.pending, (state, action) => {     
    // console.log(action)

  })

  .addCase(getServiceThunk.rejected, (state, action) => {
    // console.log(action)
  })





  .addCase(deleterServiceThunk.fulfilled, (state, action) => {
    // console.log("delete api", action.payload);
    // state.groups.filter((item) => item.id !== action.payload._id)
  })

  .addCase(getCategoryServicesThunk.fulfilled, (state, action) => {
      const {data} = action.payload.data.data
      let tempOptions=[]

      if(data.length > 0){
        for(let item of data){
          tempOptions.push({value:item._id , label:item.title });            
        }
        state.customservices = {...state.customservices,data}
        state.customserviceOptions = [...tempOptions]
      }
  })

  }
})


export const { changeLimit, changePage } = servicesSlice.actions
export default servicesSlice.reducer;