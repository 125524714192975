import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV  ;
// const api_url = `http://localhost:8081/api`


//  document  add thunk
export const addRecipientThunk = createAsyncThunk(
  'recipients/add',
  async ({ body, headers }) => {
    console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/recipients`, body, config)

      console.log(data)
      return data
    } catch (error) {

      return error
    }


  }

)

// get group thunk
export const getRecipientThunk = createAsyncThunk(
  'recipients/get',
  async ({ headers, params }) => {
   
    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params:params
    }
    try {
      const data = await axios.get(`${api_url}/recipients`, config)
      console.log(data);
      return data

    } catch (error) {
      return error

    }
  }
)

// delete group thunk
export const deleteRecipientThunk = createAsyncThunk(
  'recipients/delete',
  async ({ headers,_id }) => {
    console.log({headers,_id});
    const config = {
      headers: {
        "Authorization":  headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/recipients/${_id}`,config)
      return {data,_id}

    } catch (error) {
      return error
    }


  }
)