import React from "react";
import Sidebartoggle from "../../../common/Sidebartoggle";
import { useLocation } from 'react-router-dom';

function ViewAnnualReport() {
  const location = useLocation()
  console.log(location.state);
  return (
    <>
      <div className="common_section">
        <div
          className="d-flex justify-content-between align-items-center common_section position-relative"
          id="header"
        >
          <h2 className="mb-0">
            <Sidebartoggle />
            Annual Report Details
          </h2>
        </div>

        <section className="entity">
          <div className="entity_components">
            <div className="white_shadow_box pb-0">
              <div className="table_head px-0">
                <h3 className="mb-4">Document Title </h3>
              </div>
              <div className="order_details mt-3">
                <div className="row">
                  <div className="col-md-6 padding_right">
                    <div className="details_flex">
                      <p>
                        Account Name <span>-</span>
                      </p>
                      <h6>Cover Highlights </h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Payment status <span>-</span>
                      </p>
                      <h6>Completed</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Client Type <span>-</span>
                      </p>
                      <h6>New</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Subscription Start Date <span>-</span>
                      </p>
                      <h6>November 23, 2024</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Subscription End Date <span>-</span>
                      </p>
                      <h6>November 23, 2025</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Amount <span>-</span>
                      </p>
                      <h6>$4888</h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Description <span>-</span>
                      </p>
                      <h6>
                        One Year Business Plan Subscription - Entity Management
                      </h6>
                    </div>
                    <div className="details_flex">
                      <p>
                        Billing Address <span>-</span>
                      </p>
                      <h6>
                        Mohali, Chandigarh India, , Chandigarh, Chandigarh,
                        India, 160062
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ViewAnnualReport;
