import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`
//  peoples  add thunk
export const addPeoplesThunk = createAsyncThunk(
  "peoples/add",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.post(`${api_url}/peoples`, body, config);

      return data;
    } catch (error) {
      return error.response;
    }
  }
);



// delete peoples thunk
export const deletePeoplesThunk = createAsyncThunk(
  "peoples/delete",
  async ({ headers, _id, params }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params
    };
    try {
      const data = await axios.delete(`${api_url}/peoples/${_id}`, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);


// delete peoples thunk
export const updatePeoplesThunk = createAsyncThunk(
  "peoples/update",
  async ({ headers, body, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.patch(`${api_url}/peoples/${_id}`,body, config);
      return { data, _id };
    } catch (error) {
      return error;
    }
  }
);
// get peoples entity  thunk
export const getPeoplesEntityThunk = createAsyncThunk(
  "peoples/entity/get",
  async ({ headers, params, _id }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/entities/${_id}/peoples`, config);
      return { data, _id , params };
    } catch (error) {
      return error;
    }
  }
);

export const getPeoplesThunk = createAsyncThunk(
  "peoples/get",
  async ({ headers, params}) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/peoples`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getPeoplesStatsThunk = createAsyncThunk(
  "peoples/stats/get",
  async ({ headers, params}) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
      params: params,
    };
    try {
      const data = await axios.get(`${api_url}/peoples/stats/director`, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
