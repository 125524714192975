import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;
// const api_url = `http://localhost:8081/api`


//  document  add thunk
export const addRequirementTypesThunk = createAsyncThunk(
  'requirementsTypes/add',
  async ({ body, headers }) => {
    console.log("config", body, headers);
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.post(`${api_url}/requirementtypes`, body, config)


      return data
    } catch (error) {

      return error
    }


  }

)

// get group thunk
export const getRequirementTypesThunk = createAsyncThunk(
  'requirementsTypes/get',
  async ({ headers, params }) => {

    const config = {
      headers: {
        "Authorization": headers.Authorization
      },
      params: params
    }
    try {
      const data = await axios.get(`${api_url}/requirementtypes`, config)

      return data

    } catch (error) {
      return error

    }
  }
)

// delete group thunk
export const deleteRequirementTypesThunk = createAsyncThunk(
  'requirementsTypes/delete',
  async ({ headers, _id }) => {
    console.log({ headers, _id });
    const config = {
      headers: {
        "Authorization": headers.Authorization
      }
    }
    try {
      const data = await axios.delete(`${api_url}/requirementtypes/${_id}`, config)
      return { data, _id }

    } catch (error) {
      return error
    }


  }
)